import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import { useLoading } from '../config/loadingContext';
import { useTranslate } from '../config/translateContext';

const Main = () => {
    const [diameter, setDiameter] = useState(window.innerWidth * (window.innerWidth > 700 ? 0.4 : 1.8));
    const [showModal, setShowModal] = useState(false);
    const [currentImage, setCurrentImage] = useState(null);
    const [reklamaSlup, setReklamaSlup] = useState([]);
    const { setIsLoading } = useLoading();
    const [workAdvertisements, setWorkAdvertisements] = useState([]); 
    const [infoteka, setInfoteka] = useState([]);
    const [profiles, setProfiles] = useState([]);
    const navigate = useNavigate();
    const mediaUrl = process.env.REACT_APP_MEDIA_URL;
    const { isTranslated } = useTranslate();

    const fetchData = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/reklama-slup/`);
            

            if (response.data && response.data.reklama_slup && Array.isArray(response.data.reklama_slup)) {
                setReklamaSlup(response.data.reklama_slup);
                console.log('reklamaSlup is set to:', response.data.reklama_slup);
            } else {
                console.error('Unexpected response structure:', response.data);
                setReklamaSlup([]);
            }
        } catch (error) {
            console.error("Failed to fetch data:", error);
            setIsLoading(false);
        } finally {
            setIsLoading(false);
        }
    };

    // fetch workadvertisment
    const fetchWorkAdvertisements = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/praca/`);
            setWorkAdvertisements(response.data.work_all);
        } catch (error) {
            console.error("Failed to fetch data:", error);
        }
    };
    const fetchInfoteka = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/infoteka/`);
            // Assuming you want to access the "newest" array
            if (response.data && Array.isArray(response.data.newest)) {
                setInfoteka(response.data.newest);
            } else {
                console.error('Unexpected response structure:', response.data);
                setInfoteka([]);
            }
        } catch (error) {
            console.error("Failed to fetch data:", error);
            setInfoteka([]);
        }
    };



    const fetchProfile = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/profile/`);
            // Assuming you want to access the "newest" array
            if (response.data && Array.isArray(response.data.profile)) {
                setProfiles(response.data.profile);
            } else {
                console.error('Unexpected response structure:', response.data);
                setProfiles([]);
            }
        } catch (error) {
            console.error("Failed to fetch data:", error);
            setProfiles([]);
        }
    };
    
    
    
    const handleImageClick = (src, e) => {
        e.stopPropagation();
        e.preventDefault();
        setCurrentImage(src);
        setShowModal(true);
    };

    const handleClose = () => {
        setShowModal(false);
    };

    const handleResize = () => {
        setDiameter(window.innerWidth * (window.innerWidth > 700 ? 0.2 : 0.2));
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        setIsLoading(true);
        fetchData();
        fetchWorkAdvertisements();
        fetchInfoteka();
        fetchProfile();
        setIsLoading(false);
    }, []);

    const radius = diameter / 2; // Define radius here

    return (
        <div className='small-padding section'>
            <span className='big-text-obrotni'>OBROTNI.DE</span>
            <div className="slup">
                <img src='img/slup.png' className="central-image" alt="Central" />
                <div id="spin-container" className="spin-container">
                    {reklamaSlup.map((item, index) => (
                        <div
                            key={index}
                            className="orbit-image-container"
                            style={{
                                transform: `rotateY(${index * (360 / reklamaSlup.length)}deg) translateZ(${radius}px)`,
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transformOrigin: '0 0',
                                width: '300px',
                                height: '900px',
                            }}
                        >
                            <img
                                src={`${process.env.REACT_APP_API_BASE_URL}${item.image}`}
                                alt={`img-${index}`}
                                onClick={(e) => handleImageClick(`${process.env.REACT_APP_API_BASE_URL}${item.image}`, e)}
                                style={{
                                    cursor: 'pointer',
                                    transition: 'transform 0.5s',
                                    width: '60%', // Adjust the size of the orbiting images
                                    height: 'auto',
                                }}
                                loading='lazy'
                            />
                        </div>
                    ))}
                </div>
                <div className='row'>
                    <p className='text-danger'><strong></strong></p>
                </div>
            </div>
            <Modal show={showModal} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Image Preview</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <img src={currentImage} alt="Preview" style={{ width: '100%' }} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* work advertisement */}
            <div className='container vh30'>
                <h1>PRACA</h1>
                <div className='container work-all my-5'>
    
    <div className='scroll-container'>
        {workAdvertisements && workAdvertisements.map((adv, index) => (
            <div className='card neumorphism' key={adv.id}>
                <img src={`${mediaUrl}${adv.img}`} className="card-img-top" alt={adv.title} />
                <div className='card-category-work'>
                    <strong>{isTranslated ? adv.job.name_de : adv.job.name}</strong>
                </div>
                <div className='card-body'>
                    <h5 className='card-title'>
                        {adv.city.name}
                    </h5>
                    <p className='card-text'>
                        {adv.intro}
                    </p>
                    <Link to={`/praca/${adv.id}/`}>
                  {isTranslated ? 'Lesen' : 'Przeczytaj'}
                </Link>
                </div>
            </div>
        ))}
    </div>
    
</div>
          </div>
          
{/* infoteka */}
<div className='container vh30'>
    {/* <span className='big-text'>INFOTEKA</span> */}
    <h1>INFOTEKA</h1>
    <div className='container infoteka-all my-5'>
        <div className='scroll-container'>
            
            {infoteka.map((post, index) => (
              <div className='col-12 col-sm-12 col-md-6 col-lg-4 my-5 d-block' key={post.id}>
              <div className='card neumorphism'>
                  <img src={`${mediaUrl}${post.img}`} className="card-img-top" alt={post.title} />
                  <div className='card-category'>
                      <strong>{isTranslated ? post.presscategory.name_de : post.presscategory.name}</strong>
                  </div>
                  <div className='card-body'>
                      <h5 className='card-title'>
                          {post.title}
                      </h5>
                      <p className='card-text'>
                          {post.intro}
                      </p>
              </div>
              </div>
          </div>
            ))}
        </div>
    </div>
</div>
{/* profile */}
<div className='container nowi'>
   <h1>NOWI OBROTNI</h1>
    <div className='container obrotni-all'>
        <div className='scroll-container'>
            {profiles.map((profil, index) => (
                <div className='card neumorphism' key={profil.id}>
                  <div className='card-image'>
                  <img src={`${mediaUrl}${profil.profilimg}`} className="card-img-top" alt={profil.title} />
                    </div>
                    <div className='card-category-obrotni'>
                        <strong>{profil.job ? profil.job.name : profil.name}</strong>
                    </div>
                    <div className='card-body'>
                        <h5 className='card-title'>
                            {profil.title}
                        </h5>
                        <p className='card-text'>
                            {profil.intro}
                        </p>
                        <Link to={`/profile/${profil.slug}/`}>Odwiedź profil</Link>
                    </div>
                </div>
            ))}
        </div>
    </div>
</div>




            <style jsx>{`
                /* Styles for screens greater than 700px */
                @media (min-width: 700px) {
                    .slup {
                        position: relative;
                        width: ${diameter}px;
                        height: ${diameter}px;
                        margin: auto;
                        perspective: 1500px;
                        max-height:700px;
                    }
                    .central-image {
                        position: absolute;
                        width: 250px;
                        height: 100%;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                    .spin-container {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: -25vh;
                        left: 0%;
                        transform-style: preserve-3d;
                        animation: spin 20s infinite linear;
                        transform: translate(-50%, -50%);
                    }
                    .spin-container:hover {
                        animation-play-state: paused;
                    }
                    .spin-container img {
                        transition: transform 0.5s;
                        backface-visibility: hidden;
                        margin-top: -20vh;
                    }
                    @keyframes spin {
                        from {
                            transform: rotateY(0deg);
                        }
                        to {
                            transform: rotateY(-360deg);
                        }
                    }
                    .orbit-image-container {
                        transform-origin: 50% 50%;
                    }
                    .small-padding {
                        min-height:100vh;
                        margin-top:15vh;
                    }
                }

                /* Styles for screens less than or equal to 700px */
               @media (min-width: 700px) {
    .slup {
        position: relative;
        width: ${diameter}px;
        height: ${diameter}px;
        margin: auto;
        perspective: 1500px;
        perspective-origin: center;
    }
    .central-image {
        position: absolute;
        width: 250px;
        height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .spin-container {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transform-style: preserve-3d;
        animation: spin 20s infinite linear;
    }
    .spin-container:hover {
        animation-play-state: paused;
    }
    .orbit-image-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform-origin: center;
        transform-style: preserve-3d;
    }
    .spin-container img {
        transition: transform 0.5s;
        backface-visibility: hidden;
        margin-top: -20vh;
    }
    @keyframes spin {
        from {
            transform: rotateY(0deg);
        }
        to {
            transform: rotateY(-360deg);
        }
    }
    .small-padding {
        min-height: 100vh;
        margin-top: 15vh;
    }
}

@media (max-width: 700px) {
    .small-padding {
        min-height: 100vh;
        margin-top: 15vh;
    }
    .slup {
        position: relative;
        width: 40vw;
        height: 100vw;
        margin: auto;
        perspective: 1500px;
        perspective-origin: center;
    }
    .central-image {
        position: absolute;
        width: 100%;
        height: 500px;
        top: 57%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .spin-container {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transform-style: preserve-3d;
        animation: spin 20s infinite linear;
    }
    .spin-container:hover {
        animation-play-state: paused;
    }
    .orbit-image-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform-origin: center;
        transform-style: preserve-3d;
    }
    .spin-container img {
        transition: transform 0.5s;
        backface-visibility: hidden;
        margin-top: -20vh;
    }
    @keyframes spin {
        from {
            transform: rotateY(0deg);
        }
        to {
            transform: rotateY(-360deg);
        }
    }
}

            `}</style>
        </div>
    );
};

export default Main;
