import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../config/AuthContext';
import Container from 'react-bootstrap/Container';
import { useLoading } from '../config/loadingContext';
import Loader from '../components/Loader';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';










function LebenslaufDeDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { isAuthenticated, loading: authLoading, token } = useAuth();
  const [lebenslaufDe, setLebenslaufDe] = useState(null);
  const [error, setError] = useState(null);
  const [generated, setGenerated] = useState(false);
  const { isLoading, setIsLoading } = useLoading();
  const pdfRef = useRef(null);

  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const [editingRecord, setEditingRecord] = useState({ field: null, index: null });
  const [tempValue, setTempValue] = useState('');

  useEffect(() => {
    if (isAuthenticated && id) {
      fetchLebenslaufDe();
    }
  }, [isAuthenticated, id, token]);

  const fetchLebenslaufDe = async () => {
    if (!token) {
      setError('No access token');
      return;
    }
    try {
      const response = await axios.get(`http://localhost:8000/lebenslauf/de/${id}/`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setLebenslaufDe(response.data);
      console.log(response.data);
    } catch (error) {
      setError('Error fetching data');
    }
  };

  const handleGeneratePdf = async () => {
    const element = pdfRef.current;
    if (!element) {
      console.error('Element to render is not available');
      return;
    }
  
    // Ukryj wszystkie elementy z klasą cv-dis z animacją
    const cvDisElements = element.getElementsByClassName('cv-dis');
    Array.from(cvDisElements).forEach(el => {
      el.classList.add('hide');
    });
  
    // Poczekaj 500ms na zakończenie animacji
    await new Promise(resolve => setTimeout(resolve, 500));
  
    const images = element.getElementsByTagName('img');
    const loadPromises = Array.from(images).map(img => {
      return new Promise(resolve => {
        if (img.complete) {
          resolve();
        } else {
          img.onload = resolve;
          img.onerror = resolve;
        }
      });
    });
  
    await Promise.all(loadPromises);
  
    try {
      const canvas = await html2canvas(element, {
        useCORS: true
      });
      const data = canvas.toDataURL('image/png');
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'pt',
        format: 'a4'
      });
      const margin = 40;
      const imgProps = pdf.getImageProperties(data);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      const fileName = `lebenslauf-${lebenslaufDe.last_name}.pdf`;
  
      pdf.addImage(data, 'PNG', margin, margin, pdfWidth - 2 * margin, pdfHeight);
  
      const pdfBlob = pdf.output('blob');
  
      const formData = new FormData();
      formData.append('pdf', pdfBlob, fileName);
  
      formData.append('first_name', lebenslaufDe.first_name);
      formData.append('last_name', lebenslaufDe.last_name);
  
      const response = await axios.put(
        `http://localhost:8000/lebenslauf/de/${id}/`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`
          }
        }
      );
      pdf.save(fileName);
  
      if (response.status === 200) {
        handleShow();
      } else {
        console.error('Error uploading PDF');
      }
    } catch (error) {
      console.error('Error generating or uploading PDF', error);
    } finally {
      // Przywróć widoczność elementów
      Array.from(cvDisElements).forEach(el => {
        el.classList.remove('hide');
      });
    }
  };
  

  const handleEditClick = (field, index, value) => {
    setEditingRecord({ field, index });
    setTempValue(value);
  };

  const handleInputChange = (name, value) => {
    setTempValue((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  const handleSaveClick = async () => {
    const updatedData = { ...lebenslaufDe };

    if (editingRecord.field && editingRecord.index !== null) {
      const field = editingRecord.field;
      const index = editingRecord.index;

      updatedData[field] = updatedData[field].map((item, idx) =>
        idx === index
          ? {
              ...item,
              ...tempValue,
            }
          : item
      );
    }

    const formData = new FormData();
    formData.append('first_name', updatedData.first_name);
    formData.append('last_name', updatedData.last_name);
    formData.append('date_of_birth', updatedData.date_of_birth);
    formData.append('email', updatedData.email);
    formData.append('phone', updatedData.phone);
    formData.append('my_city', updatedData.my_city);
    formData.append('strasse', updatedData.strasse);
    formData.append('haus_nr', updatedData.haus_nr);
    formData.append('driving_license', updatedData.driving_license);
    formData.append('is_auto', updatedData.is_auto);
    formData.append('education', JSON.stringify(updatedData.education));
    formData.append('experience', JSON.stringify(updatedData.experience));
    formData.append('skill', JSON.stringify(updatedData.skill));
    formData.append('language', JSON.stringify(updatedData.language));

    if (updatedData.img instanceof File) {
      formData.append('img', updatedData.img);
    }
    if (updatedData.pdf instanceof File) {
      formData.append('pdf', updatedData.pdf);
    }

    try {
      const response = await axios.put(`http://localhost:8000/lebenslauf/de/${id}/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`
        }
      });

      if (response.status === 200) {
        setEditingRecord({ field: null, index: null });
        setLebenslaufDe(updatedData);
      } else {
        console.error('Error updating profile');
      }
    } catch (error) {
      console.error('Error updating profile', error);
    }
  };

  const handleCancelClick = () => {
    setEditingRecord({ field: null, index: null });
  };



  if (authLoading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <div>You are not logged in</div>;
  }

  if (!lebenslaufDe) {
    return <Loader />;
  }

  return (
    <div className="container vh10">
      <div ref={pdfRef}>
        <div className='row'>
          <div className='col-lg-3 ps-4 py-0 pe-0 d-flex bg-light justify-content-end'>
            <img src={lebenslaufDe.img ? lebenslaufDe.img : 'img/logo.png'} className='w-100 bg-white' />
          </div>
          <div className='col-lg-8 p-5 bg-body-tertiary d-flex align-items-center'>
            <h2 className='display-3'>{lebenslaufDe.first_name} {lebenslaufDe.last_name}</h2>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-4 p-4 mt-5'>
            <ul className='list-unstyled fs-5 purple lh-lg'>
              <li>geb. {lebenslaufDe.date_of_birth}</li>
              <li><i class="bi bi-geo-alt"></i> {lebenslaufDe.my_city}<br/>  <span className='ms-3'>{lebenslaufDe.strasse} {lebenslaufDe.haus_nr}</span></li>
              <li><i class="bi bi-telephone"></i> {lebenslaufDe.phone}</li>
              <li><i class="bi bi-envelope"></i> {lebenslaufDe.email}</li>
            </ul>
            <h2 className='my-5'>Kurse und Zertifikate</h2>
            <ul className='list-unstyled'>
              {lebenslaufDe.skill && lebenslaufDe.skill.map((skill, index) => (
             <li className='bg-light my-2 py-2 bg-gradient d-flex justify-content-between' key={index}>
                  {editingRecord.field === 'skill' && editingRecord.index === index ? (
                    <>
                      <input type="text" value={tempValue} onChange={handleInputChange} />
                      <button onClick={handleSaveClick}>Save</button>
                      <button onClick={handleCancelClick}>Cancel</button>
                    </>
                  ) : (
                    <>
                      {skill.title_de} <br /> {skill.level_de}
                      <button className='orange-button mb-3 cv-dis' onClick={() => handleEditClick('skill', index, skill.title_de)}>
                        <i className="bi bi-pencil"></i>
                      </button>
                    </>
                  )}
                </li>
              ))}
            </ul>
            <h2>Sprachen</h2>
            <ul className='list-unstyled'>
              {lebenslaufDe.language && lebenslaufDe.language.map((lang, index) => (
                <li className='bg-light my-2 py-2 bg-gradient d-flex justify-content-between' key={index}>
                  {editingRecord.field === 'language' && editingRecord.index === index ? (
                    <>
                      <input type="text" value={tempValue} onChange={handleInputChange} />
                      <button onClick={handleSaveClick}>Save</button>
                      <button onClick={handleCancelClick}>Cancel</button>
                    </>
                  ) : (
                    <>
                      {lang.name_de} - {lang.proficiency_de}
                      <button className='orange-button mb-3 cv-dis' onClick={() => handleEditClick('language', index, lang.name_de)}>
                        <i className="bi bi-pencil"></i>
                      </button>
                    </>
                  )}
                </li>
              ))}
            </ul>
            <p><strong>Führerschein Klasse: {lebenslaufDe.driving_license}</strong></p>
            <p><strong>Eigenes Auto: {lebenslaufDe.is_auto ? 'Ja' : 'Nein'}</strong></p>
          </div>
          <div className='col-md-8'>
          <h2 className='display-3 my-5'>Ausbildung</h2>
{lebenslaufDe.education && lebenslaufDe.education.map((edu, index) => (
  <div className='my-5 border-bottom-orange' key={index}>
    {editingRecord.field === 'education' && editingRecord.index === index ? (
      <>
        <input
          type="text"
          value={tempValue.institution_de || ''}
          onChange={(e) => handleInputChange('institution_de', e.target.value)}
          placeholder="Institution"
        />
        <input
          type="text"
          value={tempValue.degree_de || ''}
          onChange={(e) => handleInputChange('degree_de', e.target.value)}
          placeholder="Degree"
        />
        <input
          type="text"
          value={tempValue.field_of_study_de || ''}
          onChange={(e) => handleInputChange('field_of_study_de', e.target.value)}
          placeholder="Field of Study"
        />
        <input
          type="text"
          value={tempValue.start_date_de || ''}
          onChange={(e) => handleInputChange('start_date_de', e.target.value)}
          placeholder="Start Date"
        />
        <input
          type="text"
          value={tempValue.end_date_de || ''}
          onChange={(e) => handleInputChange('end_date_de', e.target.value)}
          placeholder="End Date"
        />
        <button onClick={handleSaveClick}>Save</button>
        <button onClick={handleCancelClick}>Cancel</button>
      </>
    ) : (
      <>
        <h3>{edu.institution_de}</h3>
        <p>{edu.degree_de} in {edu.field_of_study_de}</p>
        <p>Von {edu.start_date_de} bis {edu.end_date_de}</p>
        <button className='orange-button mb-3 cv-dis' onClick={() => handleEditClick('education', index, edu)}>
          <i className="bi bi-pencil"></i>
        </button>
      </>
    )}
  </div>
))}
            <h2 className='display-3 my-5'>Erfahrung</h2>
{lebenslaufDe.experience && lebenslaufDe.experience.map((exp, index) => (
  <div className='my-5 border-bottom-orange' key={index}>
    {editingRecord.field === 'experience' && editingRecord.index === index ? (
      <>
        <input
          type="text"
          value={tempValue.company_de || ''}
          onChange={(e) => handleInputChange('company_de', e.target.value)}
          placeholder="Company"
        />
        <input
          type="text"
          value={tempValue.position_de || ''}
          onChange={(e) => handleInputChange('position_de', e.target.value)}
          placeholder="Position"
        />
        <input
          type="text"
          value={tempValue.start_date_de || ''}
          onChange={(e) => handleInputChange('start_date_de', e.target.value)}
          placeholder="Start Date"
        />
        <input
          type="text"
          value={tempValue.end_date_de || ''}
          onChange={(e) => handleInputChange('end_date_de', e.target.value)}
          placeholder="End Date"
        />
        <button onClick={handleSaveClick}>Save</button>
        <button onClick={handleCancelClick}>Cancel</button>
      </>
    ) : (
      <>
        <h3>{exp.company_de}</h3>
        <p>{exp.position_de}</p>
        <p>{exp.start_date_de} - {exp.end_date_de}</p>
        <button className='orange-button mb-3 cv-dis' onClick={() => handleEditClick('experience', index, exp)}>
          <i className="bi bi-pencil"></i>
        </button>
      </>
    )}
  </div>
))}

          </div>
        </div>
        <div className='row position-absolute bottom-0'>
          <p className='text-center'>Ich bin mit der Verarbeitung meiner personenbezogenen Daten zum Zwecke der Rekrutierung einverstanden.</p>
          <p className='text-center'>Finden Sie Mitarbeiter auf dem Portal <span className='purple'><strong>www.obrotni.de</strong></span>, das entwickelt wurde, um die Zusammenarbeit von Unternehmern aus Polen und Deutschland zu erleichtern.</p>
        </div>    
      </div>
      <div className='row'>
        <Button className='btn orange-button w-100 mt-2' onClick={handleGeneratePdf}>wygeneruj PDF</Button>
      </div>
      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Udało się!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Twój Lebenslauf jest zawsze dostępny w Twoim profilu. Możesz teraz jednym kliknięciem zaaplikować na łamach www.obrotni.de.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>   
    </div>
  );
  
  
  
}

export default LebenslaufDeDetail;
