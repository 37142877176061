import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'; 
import axiosInstance from '../config/axiosConfig';

const CityDetail = () => {
  const [cityData, setCityData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { slug } = useParams();
  const [activeCategory, setActiveCategory] = useState(null);
  const [filteredObrotni, setFilteredObrotni] = useState([]); // Nowy stan dla filtrowanych obrotni
  const mediaUrl = process.env.REACT_APP_MEDIA_URL;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(`/stadt/${slug}/`);
        setCityData(response.data);
        setFilteredObrotni(response.data.obrotni); // Domyślnie ustawiamy wszystkich obrotni
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [slug]);

  if (loading) return <p>Loading...</p>;
  if (!cityData) return <p>No data available</p>;

  const handleCategoryClick = (categoryId) => {
    setActiveCategory(activeCategory === categoryId ? null : categoryId);
  };

  const handleJobClick = (jobId) => {
    const obrotniForJob = cityData.obrotni.filter(obrotni => obrotni.job && obrotni.job.id === jobId);
    setFilteredObrotni(obrotniForJob);
  };

  return (
    <div className='container vh30'>
      <h1>{cityData.city.name}</h1>
      
      <h2 className='mt-5'>Kategorie:</h2>
      
      {/* Lista Kategorii */}
      <div className='category-list job-categories'>
        {cityData.categories.length > 0 ? (
          cityData.categories.map((category) => (
            <div key={category.id} className='category'>
              <h3 onClick={() => handleCategoryClick(category.id)} className='category-name'>
                {category.name}
              </h3>
            </div>
          ))
        ) : (
          <p>No categories found.</p>
        )}
      </div>
      
      {/* Lista Jobs */}
      {activeCategory && (
        <div className='container mt-4'>
          <div className='d-flex gap-3'>
            {cityData.jobs
              .filter((job) => job.category.id === activeCategory)
              .map((job) => (
                <div key={job.id} className='job-item' onClick={() => handleJobClick(job.id)}>
                  <h4>{job.name}</h4>
                  <p>{job.opis}</p>
                </div>
              ))
            }
            {cityData.jobs.filter((job) => job.category.id === activeCategory).length === 0 && (
              <p>No jobs found for this category.</p>
            )}
          </div>
        </div>
      )}

      <h2 className='mb-5'>Obrotni:</h2>
      <div className='obrotni-all'>
        <div className='scroll-container'>
          {filteredObrotni.length > 0 ? (
            filteredObrotni.map((profil) => (
              <div className='card neumorphism' key={profil.id}>
                <div className='card-image'>
                  <img src={`${mediaUrl}${profil.profilimg}`} className="card-img-top" alt={profil.title} />
                </div>
                <div className='card-category-obrotni'>
                  <strong>{profil.job ? profil.job.name : profil.name}</strong>
                </div>
                <div className='card-body'>
                  <h5 className='card-title'>
                    {profil.title}
                  </h5>
                  <p className='card-text'>
                    {profil.description}
                  </p>
                  <a href={`/profile/${profil.slug}/`}>Odwiedź profil</a>
                </div>
              </div>
            ))
          ) : (
            <p>No profiles found.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CityDetail;
