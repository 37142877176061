import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../config/axiosConfig';
import { useTranslate } from '../config/translateContext';
import { AuthContext } from '../config/AuthContext';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import { ChatDots } from 'react-bootstrap-icons';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

const Contacts = () => {
  const { slug } = useParams(); // Pobierz slug z URL
  const [contacts, setContacts] = useState([]);
  const [contactId, setContactID]=useState([]);
  const [error, setError] = useState(null);
  const { isTranslated } = useTranslate();
  const { user, isAuthenticated, logout, hasSzukajacy, hasObrotnyuser , userSlug } = useContext(AuthContext); // Upewnij się, że userSlug jest poprawnie ustawione
  const [activeRoom, setActiveRoom] = useState(null);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [ws, setWs] = useState(null);
  const [receiverId, setReceiverId] = useState(null);
  const [isRoomCreated, setIsRoomCreated] = useState(false); 
  const [roomId, setRoomId] = useState(null);
  const [show, setShow] = useState(false);
  const [validated, setValidated] = useState(false);
  const [roomName, setRoomName] = useState('');
  const [loading, setLoading] = useState(false);
  const [sender, setSender] = useState(null);
  const [myRooms, setMyRooms] = useState();
  const [useContacts, setUseContacts]= useState(null);
  const [useRooms, setUseRooms] = useState(null);
  const [contactList, setContactList] = useState(null);
  

  const handleShow = async (contactId) => {
    console.log('handleShow called with contactId:', contactId); // Dodaj log

    try {
      const response = await axiosInstance.get('/chat/check-room/', {
        params: {
          profileId: user.profile_id,
          second: contactId
        }
      });
      console.log('Raw response:', response); // Dodaj log
      const { room_exists, room, messages, my_rooms } = response.data;

  
      if (!room_exists) {
        await handlePost(contactId, slug);
      } else {
        setActiveRoom(room.id);
        setMessages(messages);
        setReceiverId(contactId); // Ustaw receiverId na contactId
        setIsRoomCreated(true);
  
      }
    } catch (error) {
      console.error('Failed to check room existence:', error);
    }
  };
  
  




  const handleSwitchToContact = async () => {
    if (!isAuthenticated) {
      console.error('User is not authenticated');
      return;
    }
  
    console.log('handleSwitchToContact called with profileId:', user.profile_id); // Dodaj log
  
    try {
      const response = await axiosInstance.get('/chat/check-room/', {
        params: {
          profileId: user.profile_id  // Przekaż profile_id z obiektu user
        }
      });

      const { contact_list_true } = response.data;
      console.log('Response from backend:', response.data); // Dodaj log
      console.log('Contact list is true:', contact_list_true); // Dodaj log
  
      if (contact_list_true) {
        setUseContacts(true);
        setUseRooms(false);
      }
    } catch (error) {
      console.error('Failed to check room existence:', error);
    }
  };







  const handleSwitchToRooms = async () => {
    if (!isAuthenticated) {
      console.error('User is not authenticated');
      return;
    }
  
    console.log('handleSwitchToRoom called with profileId:', user.profile_id); // Dodaj log
  
    try {
      const response = await axiosInstance.get('/chat/check-room/', {
        params: {
          profileId: user.profile_id
        }
      });
      console.log('Response from backend:', response.data);
      const { room_exists, my_rooms } = response.data;
  
      if (room_exists && Array.isArray(my_rooms)) {
        setMyRooms(my_rooms);
        setUseContacts(false);
        setUseRooms(true);
        console.log('myRooms set to:', my_rooms);
      } else {
        console.error('my_rooms is not an array or room does not exist');
        setMyRooms([]);
        setUseRooms(true);
      }
    } catch (error) {
      console.error('Failed to check room existence:', error);
    }
  };





  const handlePost = async (receiverId, slug) => {
    console.log('handlePost called with receiverId:', receiverId, 'and slug:', slug); // Dodaj log

    // Sprawdzenie wartości
    console.log('Type of receiverId:', typeof receiverId);
    console.log('Type of slug:', typeof slug);

    try {
      const response = await axiosInstance.post('/chat/room/create/', {  // Upewnij się, że ścieżka jest poprawna
        receiver: receiverId,
        sender: slug, // Użyj slug zamiast senderId
      });
      console.log('Room created with response:', response.data); // Dodaj log

      // Ustawienie stanu na podstawie odpowiedzi z serwera
      setActiveRoom(response.data.room_id);
      setMessages(response.data.messages);
      setReceiverId(response.data.receiver_id); // Ustaw receiverId
      setIsRoomCreated(true);
    } catch (error) {
      console.error('Failed to create room:', error);
    }
  };

  const handleSubmit = (event, contactId) => {
    event.preventDefault();
    handlePost(contactId, slug);
  };

  const connectWebSocket = (roomId) => {
    const wsBaseURL = 'obrotni.info';
  
  // Określ protokół WebSocket
  const wsProtocol = 'wss://';
  
      const wsURL = `${wsProtocol}${wsBaseURL}/ws/private/${roomId}/`;
    const newWs = new WebSocket(wsURL);
    setWs(newWs);

    newWs.onopen = () => {
      console.log('WebSocket is open');
    };

    newWs.onclose = () => {
      console.log('WebSocket is closed');
      setTimeout(() => connectWebSocket(roomId), 1000); // Próba ponownego połączenia po 1 sekundzie
    };

    newWs.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log('Otrzymane dane:', data);
  
      if (data.type === 'new_message') {
        setMessages((prevMessages) => [...prevMessages, {
          id: data.message_id,
          content: data.message,
          sender: {
            id: data.sender_id,
            user: {
              username: data.sender_username
            }
          },
          created_at: data.created_at
        }]);
      } else if (data.type === 'chat_history') {
        setMessages((prevMessages) => [
          ...prevMessages,
          ...data.messages.map(msg => ({
            id: msg.id,
            content: msg.content,
            sender: {
              id: msg.sender_id,
              user: {
                username: msg.sender_username
              }
            },
            created_at: msg.created_at
          }))
        ]);
      }
    };
  

    return () => {
      if (newWs && newWs.readyState === WebSocket.OPEN) {
        newWs.close();
      }
    };
  };

  useEffect(() => {
    if (activeRoom) {
      const cleanup = connectWebSocket(activeRoom);
      return cleanup;
    }
  }, [activeRoom]);

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const response = await axiosInstance.get('/contacts/');
        console.log('Contacts fetched:', response.data);
        setContacts(response.data);
      
      } catch (error) {
        setError(isTranslated ? 'Fehler beim Abrufen der Kontakte' : 'Błąd podczas pobierania kontaktów');
      }
    };

    fetchContacts();
  }, [isTranslated]);

  const sendMessage = async () => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      const messageData = {
        message: input,
        sender: {
          sender_id: user.profile_id, 
          user: {
            username: user.username
          }
        }
      };
      ws.send(JSON.stringify(messageData));
  
      try {
        const response = await axiosInstance.post(`/private/${activeRoom}/`, {
          content: input,
          receiver: receiverId
        });
        console.log('Message sent:', response.data);
        
        // Dodaj dane nadawcy do wiadomości przed dodaniem do stanu
        const newMessage = {
          ...response.data,
          content: input, 
          sender: {
            sender_id: user.profile_id, 
            user: {
              username: user.username
            }
          }
        };
        setMessages((prevMessages) => [...prevMessages, newMessage]); // Dodaj nową wiadomość do istniejącej listy
      } catch (error) {
        console.error('Failed to save message:', error);
      }
  
      setInput('');
    } else {
      console.error('WebSocket is not open. Cannot send message.');
    }
  };

  return (
    <div className="container-fluid vh30 mb-5 ">    
      <div className='row'>
      <Breadcrumb>

    <Breadcrumb.Item onClick={handleSwitchToContact}>
      Kontakty
    </Breadcrumb.Item>
    <Breadcrumb.Item onClick={handleSwitchToRooms}>
      Konwersacje
    </Breadcrumb.Item>

    </Breadcrumb>
        {useContacts ? (
          <div className='col-lg-3 sky contact-list mb-5'>
            <h2 className='ps-4'>{isTranslated ? 'Meine Kontakte' : 'Moje kontakty'}</h2>
            <Accordion>
              {contacts.map(contact => (
                <Accordion.Item eventKey={contact.id.toString()} key={contact.id}>
                  <Accordion.Header>{contact.name} {contact.surname}</Accordion.Header>
                  <Accordion.Body>
                    <ul className="list-group-flush border py-3">
                      <li className="list-group-item">
                        <strong>{contact.name} {contact.surname}</strong><br />
                      </li>
                      <li className="list-group-item">
                        {contact.job}
                      </li>
                      <li className="list-group-item">
                        {contact.land}
                      </li>
                      <li className="list-group-item">
                        {contact.city}
                      </li>
                      <li className="list-group-item">
                        <a href={contact.obrotni_url} target="_blank" rel="noopener noreferrer">
                          {isTranslated ? 'Profil-Link' : 'Link do profilu'}
                        </a>
                      </li>
                      <li className="list-group-item">
                        <Button variant="link" onClick={() => {
                          console.log('Button clicked with contactId:', contact.id); // Dodaj log
                          handleShow(contact.id);
                        }}>
                          <ChatDots /> {isTranslated ? 'Chat starten' : 'Rozpocznij czat'}
                        </Button>
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
        ) : (
          <div className='col-lg-3 sky contact-list mb-5'>
          <h2 className='ps-4'>{isTranslated ? 'Meine Konversationen' : 'Moje konwersacje'}</h2>
          <Accordion>
      {myRooms && myRooms.map(room => (
        <Accordion.Item eventKey={room.id.toString()} key={room.id}>
          <Accordion.Header>{isTranslated ? 'Konversation mit' : 'Konwersacja z'} {room.first.user.username}</Accordion.Header>
          <Accordion.Body>
            <ul className="list-group-flush border py-3">
              <li className="list-group-item">
                <strong>{isTranslated ? 'Konversation mit' : 'Konwersacja z'} {room.first.user.username}</strong><br />
              </li>
              <li className="list-group-item">
                <Button variant="link" onClick={() => {
                  console.log('Button clicked with roomId:', room.id); // Dodaj log
                  handleShow(room.id);
                }}>
                  <ChatDots /> {isTranslated ? 'Chat fortsetzen' : 'Kontynuuj czat'}
                </Button>
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
        </div>
        )}
        <div className='col-lg-8 border chat-place'>
          {activeRoom ? (
            <div>
              <h2>Private Chat Room:</h2>
              <div className="chat-messages">
                {messages.map((msg, index) => (
                  <div key={index}>
                    <strong>{msg.sender && msg.sender.user ? msg.sender.user.username : 'Unknown'}</strong>: {msg.content} <em>{msg.created_at}</em>
                  </div>
                ))}
              </div>
              {isRoomCreated ? (
                <form onSubmit={(e) => { e.preventDefault(); sendMessage(); }} className="reply-form">
                  <textarea
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    rows="2"
                  ></textarea>
                  <input type="hidden" value={receiverId} name="receiver" />
                  <button type="submit">
                    {isTranslated ? 'Senden' : 'Wyślij'}<i className="bi bi-send"></i>
                  </button>
                </form>
              ) : (
                <form onSubmit={(event) => handleSubmit(event, activeRoom)}>
                  <p>Rozpoczynasz konwersację</p>
                  <Button variant="link" type='submit'>
                    <ChatDots /> {isTranslated ? 'Chat starten' : 'Rozpocznij czat'}
                  </Button>
                </form>
              )}
            </div>
          ) : (
            <p>{isTranslated ? 'Wählen Sie einen Kontakt, um zu chatten' : 'Wybierz kontakt, aby rozpocząć czat'}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Contacts;
