import React, { useState } from 'react';
import { useAuth } from '../config/AuthContext';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Loader from '../components/Loader';
import { useTranslate } from '../config/translateContext';
const PostCreate = () => {
    const { isAuthenticated, hasObrotny } = useAuth();
    const [title, setTitle] = useState('');
    const [intro, setIntro] = useState('');
    const [img, setImg] = useState(null);
    const [banner, setBanner] = useState(null);
    const [akapity, setAkapity] = useState([{ content: '', img: null }]);
    const [sources, setSources] = useState(''); // Dodane pole sources
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const {isTranslated} = useTranslate();

    const handleAkapitChange = (index, e) => {
        const newAkapity = [...akapity];
        newAkapity[index][e.target.name] = e.target.value;
        setAkapity(newAkapity);
    };

    const handleAkapitFileChange = (index, e) => {
        const newAkapity = [...akapity];
        newAkapity[index]['img'] = e.target.files[0];
        setAkapity(newAkapity);
    };

    const addAkapit = () => {
        setAkapity([...akapity, { content: '', img: null }]);
    };

    const removeAkapit = (index) => {
        const newAkapity = [...akapity];
        newAkapity.splice(index, 1);
        setAkapity(newAkapity);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        const token = localStorage.getItem('access_token');
        if (!token) {
            setError('No access token');
            return;
        }

        const formData = new FormData();
 
        formData.append('title', title);
        formData.append('intro', intro);
        formData.append('sources', sources); // Dodane pole sources
        if (img) {
            formData.append('img', img);
        }
        if (banner) {
            formData.append('banner', banner);
        }
        akapity.forEach((akapit, index) => {
            formData.append(`akapity[${index}][content]`, akapit.content);
            if (akapit.img) {
                formData.append(`akapity[${index}][img]`, akapit.img);
            }
        });

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/post/create/`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response.status === 201) {
                navigate(`/post/success`); // Zastąp rzeczywistą ścieżką
            } else {
                setError('Failed to create post. Please try again later.');
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            setError(error.message || 'An error occurred');
        } finally {
            setIsLoading(false);
        }
    };

    const handleFileChange = (e) => {
        setImg(e.target.files[0]);
    };

    if (!isAuthenticated) {
        return <h1 className='big-text vh10'>Nie jesteś zalogowany</h1>;
    }
    if (isLoading) {
        return <Loader />;
    }
    return (
<Container className="vh30">
    <h2>{isTranslated ? 'Erstellen Sie einen neuen Beitrag' : 'Stwórz nowy post'}</h2>
    <Form onSubmit={handleSubmit} method='post'>
        <Form.Group controlId="title">
            <Form.Label>{isTranslated ? 'Titel' : 'Tytuł'}</Form.Label>
            <Form.Control 
                type="text" 
                value={title} 
                onChange={(e) => setTitle(e.target.value)} 
                required 
            />
        </Form.Group>
        <Form.Group controlId="intro" className='my-5'>
            <Form.Label>{isTranslated ? 'Einführung' : 'Wstęp'}</Form.Label>
            <p>{isTranslated ? '*Was du hier schreibst, erscheint auf der Startseite unter dem Bild' : '*To co tu napiszesz pojawi się na głównej stronie pod zdjęciem'}</p>
            <Form.Control 
                as="textarea" 
                rows={3} 
                value={intro} 
                onChange={(e) => setIntro(e.target.value)} 
                required 
            />
        </Form.Group>
        <Form.Group controlId="img" className='my-5'>
            <Form.Label>{isTranslated ? 'Bild für den Artikel' : 'Grafika do artykułu'}</Form.Label>
            <Form.Control 
                type="file" 
                onChange={handleFileChange} 
            />
        </Form.Group>
        <Form.Group controlId="banner" className='my-5'>
            <Form.Label>{isTranslated ? 'Ihr Werbebanner' : 'Twój banner reklamowy'}</Form.Label>
            <Form.Control 
                type="file" 
                onChange={(e) => setBanner(e.target.files[0])} // Zaktualizowano handler dla banner
            />
        </Form.Group>
        <p>{isTranslated ? '*Sie können den Text in mehrere Absätze unterteilen und jedem ein Bild hinzufügen, aber Sie können auch formatierten Text aus z.B. Word einfügen. Die Seite wird seine Formatierung beibehalten' : '*Możesz rozdzielić treść na kilka akapitów i do każdego dodać grafikę, ale możesz też wkleić sformatowany tekst z np. Word. Strona zachowa jego formatowanie'}</p>
        {akapity.map((akapit, index) => (
            <div key={index} className="mb-5">
                <Form.Group controlId={`akapity[${index}][content]`} className='my-5'>
                    <Form.Label>{isTranslated ? `Absatz ${index + 1}` : `Akapit ${index + 1}`}</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        name="content"
                        value={akapit.content}
                        onChange={(e) => handleAkapitChange(index, e)}
                        required
                    />
                </Form.Group>
                <Form.Group controlId={`akapity[${index}][img]`} className='my-5'>
                    <Form.Label>{isTranslated ? `Bild für Absatz ${index + 1}` : `Grafika do akapitu ${index + 1}`}</Form.Label>
                    <Form.Control
                        type="file"
                        name="img"
                        onChange={(e) => handleAkapitFileChange(index, e)}
                    />
                </Form.Group>
                <Button variant="danger" onClick={() => removeAkapit(index)}>
                    {isTranslated ? 'Absatz entfernen' : 'Usuń akapit'}
                </Button>
            </div>
        ))}
        <Form.Group controlId="sources" className='my-5'>
            <Form.Label>{isTranslated ? 'Quellen' : 'Źródła'}</Form.Label>
            <Form.Control 
                as="textarea" 
                rows={3} 
                value={sources} 
                onChange={(e) => setSources(e.target.value)} 
            />
        </Form.Group>
        <Button variant="secondary" onClick={addAkapit}>
            {isTranslated ? 'Absatz hinzufügen' : 'Dodaj akapit'}
        </Button>
        <Button variant="primary" type="submit" className='mx-3'>
            {isTranslated ? 'Einreichen' : 'Wyślij'}
        </Button>
        {error && <p className="text-danger mt-3">{error}</p>}
    </Form>
</Container>



    );
};

export default PostCreate;
