import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import '../styles/home.css';
import NewsletterForm from '../components/newsletter';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import gsap from "gsap";
import axios from 'axios'; // Import Axios
import { fadeAnimation } from '../animations/gsap';
import { LeftFadeAnimation } from '../animations/gsap';
import { RightFadeAnimation } from '../animations/gsap';

import { useLoading } from '../config/loadingContext';
import { useTranslate } from '../config/translateContext';
import { Row } from 'react-bootstrap/Row';



function Intro() {
  const [data, setData] = useState({paragraphs: [], infoteka: [], partners: [], });
  const { setIsLoading } = useLoading();
  const [error, setError] = useState('');
  const myFade = useRef(null);

  const [translatedTitles, setTranslatedTitles] = useState({});
  const [translatedIntros, setTranslatedIntros] = useState({});
  const [translatedParagraphs, setTranslatedParagraphs] = useState({});

  const { isTranslated, toggleTranslate } = useTranslate();






  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        // Directly use Axios to make the HTTP request
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/`);
        setData(response.data); // Set data with the response data
      } catch (error) {
        console.error("Failed to fetch data:", error);
        setError("Failed to load data.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

  }, []);
  useEffect(() => {
    if (myFade.current) {
        fadeAnimation(myFade.current);
    }
}, [data]);

  // Directly specifying the base URL here
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const mediaUrl = process.env.REACT_APP_MEDIA_URL;



  useEffect(() => {
    if (data) {
      // Opóźnienie może nie być potrzebne, ale jeśli animacja nadal się nie uruchamia, możesz je dodać
      LeftFadeAnimation();
    }
  }, [data]); 

  
  useEffect(() => {
    if (data) {
      // Opóźnienie może nie być potrzebne, ale jeśli animacja nadal się nie uruchamia, możesz je dodać
      RightFadeAnimation();
    }
  }, [data]); 





  const translateTexts = async (text, type, postId = null) => {
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/translate/`;
    try {
      const response = await axios.post(apiUrl, { text: text });
      const translatedText = response.data.translated_text;
  
      if (type === 'title' && postId !== null) {
        setTranslatedTitles(prevTitles => ({
          ...prevTitles,
          [postId]: translatedText // Aktualizuje stan dodając przetłumaczony tytuł dla konkretnego postId
        }));
      } else if (type === 'intro' && postId !== null) {
        setTranslatedIntros(prevIntros => ({
          ...prevIntros,
          [postId]: translatedText // Aktualizuje stan dodając przetłumaczone wprowadzenie dla konkretnego postId
        }));
      }
      else if (type === 'paragraph' && postId !== null) {
        setTranslatedParagraphs(prevParagraphs => ({
          ...prevParagraphs,
          [postId]: translatedText // Aktualizuje stan dodając przetłumaczoną treść dla konkretnego paragrafu
        }));
      }
    } catch (error) {
      console.error("Błąd podczas tłumaczenia tekstu:", error);
    }
  };



  return (

<>

<section id='intro' className='section'>
<div className='container' ref={myFade}>


        <div className='row py-5 d-flex align-items-center'>
          <div className='col-lg-4 h-100 col-sm-6 col-6 d-flex 
          align-items-center justify-content-lg-end justify-content-sm-end'>
          {data && data.hero && data.hero.img1 && (
          
              <img loading='lazy' src={`${mediaUrl}${data.hero.img1}`} alt="Hero Image" className="img-fluid object-fit-fill" />
            )}
          </div>
          <div className='col-lg-4 h-100 col-sm-6 col-12 col-sm-12 text-center  order-3 order-lg-2 d-flex
          justify-content-center
          align-items-center '>
          {data && data.hero && data.hero.img2 && (
              <img loading='lazy' src={`${mediaUrl}${data.hero.img2}`} alt="Hero Image" className="rotating-object img-fluid object-fit-fill" />
            )}
          </div>
          <div className='col-lg-4 h-100 col-sm-6 col-6 order-2
            d-flex align-items-center justify-content-lg-start justify-content-sm-center'>
          {data && data.hero && data.hero.img3 && (
              <img loading='lazy' src={`${mediaUrl}${data.hero.img3}`} alt="Hero Image" className="img-fluid object-fit-fill" />
            )}
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-4 h-100 col-4 col-sm-4  d-flex align-items-center 
          justify-content-lg-end justify-content-sm-center'>
          {data && data.hero && data.hero.img4 && (
              <img loading='lazy' src={`${mediaUrl}${data.hero.img4}`} alt="Hero Image" className="img-fluid object-fit-fill" />
            )}
          </div>
          <div className='col-lg-4 h-100 col-4 col-sm-4 d-flex 
          align-items-center justify-content-lg-center justify-content-sm-center'>
          {data && data.hero && data.hero.img5 && (
              <img loading='lazy' src={`${mediaUrl}${data.hero.img5}`} alt="Hero Image" className="img-fluid object-fit-fill" />
            )}
          </div>
          <div className='col-lg-4 h-100 col-4 col-sm-4 d-flex align-items-center 
          justify-content-lg-start justify-content-sm-center'>
          {data && data.hero && data.hero.img6 && (
              <img loading='lazy' src={`${mediaUrl}${data.hero.img6}`} alt="Hero Image" className="img-fluid object-fit-fill" />
            )}
          </div>
        </div>


        <div className='row fading section'>
          <div className='col-lg-12'>
          {data && data.hero && data.hero.title && (
            <h1 className='text-center title logo-h1 ' >{data.hero.title}</h1>
              
            )}
            
            {data && data.hero && data.hero.title && (
        <h3 className='text-center fading' >{data.hero.subtitle}</h3>
              
            )}

          </div>
        </div>
      </div>
</section>
<section id='obrotni' className=''>


  <div className='container py-5 fading'>
  {data && data.paragraphs && data.paragraphs.map((paragraph, index) => (
    <div key={paragraph.id} className='row mb-5 flex-lg-row flex-column-reverse section'>
      {paragraph.img ? (
        index % 2 === 0 ? (
          <>
            <div className='col-12 col-lg-6 col-md-6 text-end leftFade'>
              <img loading='lazy' src={`${mediaUrl}${paragraph.img}`} alt="Paragraph Image" className="img-fluid w-100 leftFade" />
            </div>
            <div className='col-12 col-lg-4 col-md-4 d-flex align-items-center rightFade'>
              <div className='d-block'>
                <button className='btn btn-link purple' onClick={() => translateTexts(paragraph.content, 'paragraph', paragraph.id)}>Übersetzen ins Deutsch</button>
                <div dangerouslySetInnerHTML={{ __html: translatedParagraphs[paragraph.id] || paragraph.content }} />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className='col-12 col-lg-4 col-md-4 d-flex align-items-center leftFade'>
              <div className='d-block  text-end'>
                <button className='btn btn-link purple' onClick={() => translateTexts(paragraph.content, 'paragraph', paragraph.id)}>Übersetzen ins Deutsch</button>
                <div dangerouslySetInnerHTML={{ __html: translatedParagraphs[paragraph.id] || paragraph.content }} className='text-end' />
              </div>
            </div>
            <div className='col-12 col-sm-12 col-lg-6 col-md-6 fading text-start rightFade'>
              <img loading='lazy' src={`${mediaUrl}${paragraph.img}`} alt="Paragraph Image" className="img-fluid w-100 fading" />
            </div>
          </>
        )
      ) : (
        <div className='col-12 col-lg-12 col-md-12 d-flex align-items-center rightFade'>
          <div className='d-block'>
            <button className='btn btn-link purple' onClick={() => translateTexts(paragraph.content, 'paragraph', paragraph.id)}>Übersetzen ins Deutsch</button>
            <div dangerouslySetInnerHTML={{ __html: translatedParagraphs[paragraph.id] || paragraph.content }} />
          </div>
        </div>
      )}
    </div>
  ))}
</div>

<div className='delicate container-fluid'>
<div className='row'>
  <h2 className='my-5'>Partnerzy projektu</h2>
<div className='row py-5 d-flex justify-content-center'>
{data && data.partners &&data.partners.map((partner, index) => (
    <div key={partner.id} className='col col-sm-2 col-md-1 col-lg-1'>
      <img loading='lazy' src={`${mediaUrl}${partner.logo}`} alt="Partner Image" className="img-fluid w-100" />
    </div>
  ))}
</div>

</div>
</div>
</section>

<div className='container py-5 '>
  <NewsletterForm />
</div>


</>

      );
    }
    
export default Intro;
