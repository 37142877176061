import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../config/AuthContext';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import axios from 'axios';
import { useTranslate } from '../config/translateContext';
import { useParams, useNavigate } from 'react-router-dom';
import NewsletterForm from '../components/newsletter';

function CreateLebenslauf() {
    const { isAuthenticated, loading: authLoading, hasObrotny, hasSzukajacy, userSlug } = useAuth();
    const [land, setLand] = useState('');
    const [miasto, setMiasto] = useState('');
    const [landList, setLandList] = useState([]); // Initialize with an empty array
    const [profileData, setProfileData] = useState({
        name: '',
        surname: '',
        email: '',
        birth: '',
        slug: '',
        strasse: '',
        haus_nr: '',
        phone: '',
        plz : '',
        land : '',
        miasto: '',
    });
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const { isTranslated } = useTranslate();
    const [inputValue, setInputValue] = useState('');
    const [degree, setDegree] = useState('');
    const [fieldOfStudy, setFieldOfStudy] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [items, setItems] = useState([]);
    const [expItems, setExpItems] = useState([]);
    const [skillItems, setSkillItems] = useState([]);
    const [editingIndex, setEditingIndex] = useState(null);
    const [expEditingIndex, setExpEditingIndex] = useState(null);
    const [skillEditingIndex, setSkillEditingIndex] = useState(null);
    const [created, setCreated] = useState(false);
    const [createdId, setCreatedId] = useState(null);
    const { id } = useParams();

    const [company, setCompany] = useState('');
    const [position, setPosition] = useState('');
    const [expStartDate, setExpStartDate] = useState('');
    const [expEndDate, setExpEndDate] = useState('');

    const [drivingLicense, setDrivingLicense] = useState('');

    const [skillName, setSkillName] = useState('');
    const [level, setLevel] = useState('');
    const [description, setDescription] = useState('');

    const [languageItems, setLanguageItems] = useState([]);
    const [languageName, setLanguageName] = useState('');
    const [proficiency, setProficiency] = useState('');
    const [languageEditingIndex, setLanguageEditingIndex] = useState(null);

    const [selectedFile, setSelectedFile] = useState(null);
    const [isAuto, setIsAuto] = useState(null);

    const [isHovered, setIsHovered] = useState(false);
    const fileInputRef = useRef(null);

    const [hasCv, setHasCv] = useState(false);
    const [errors, setErrors] = useState({});



    const validateProfileData = () => {
        const newErrors = {};
        if (!profileData.name) newErrors.name = 'Imię jest wymagane';
        if (!profileData.surname) newErrors.surname = 'Nazwisko jest wymagane';
        if (!profileData.email) newErrors.email = 'Email jest wymagany';
        if (!profileData.birth) newErrors.birth = 'Data urodzenia jest wymagana';
        if (!profileData.slug) newErrors.slug = 'Slug jest wymagany';
        if (!profileData.strasse) newErrors.strasse = 'Ulica jest wymagana';
        if (!profileData.haus_nr) newErrors.haus_nr = 'Nr domu jest wymagany';
        if (!profileData.phone) newErrors.phone = 'Numer telefonu jest wymagany';
        if (!profileData.plz) newErrors.plz = 'Kod pocztowy jest wymagany';
        if (!profileData.land) newErrors.land = 'Kraj jest wymagany';
        if (!profileData.miasto) newErrors.miasto = 'Miasto jest wymagane';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    









    const fetchProfileData = async () => {
        if (!userSlug) {
            console.error('User slug is not available');
            return;
        }

        const token = localStorage.getItem('access_token');
        console.log(userSlug);
   
        const slug = userSlug;

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/profile/${userSlug}/`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const profile = response.data.profile;

            setProfileData({
                name: profile.name,
                surname: profile.surname,
                email: profile.user.email,
                birth: profile.birth,
                slug: profile.slug,
                strasse: profile.strasse,
                haus_nr: profile.haus_nr,
                phone: profile.phone,
                plz : profile.kod,
                land : profile.land
            });

            setLand(profile.land ? profile.land.name : '');
            setMiasto(profile.miasto ? profile.miasto : '');
            
            console.log(response.data);
        } catch (error) {
            setError(`Error fetching profile data: ${error.response ? error.response.statusText : error.message}`);
        }
    };

    const fetchLebenslaufData = async () => {
        const token = localStorage.getItem('access_token');
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/lebenslauf/create/`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const { landy, has_cv } = response.data;
            setLandList(landy);
            setHasCv(has_cv); // Nowy stan do przechowywania informacji o Lebenslauf
        } catch (error) {
            setError(`Error fetching Lebenslauf data: ${error.response ? error.response.statusText : error.message}`);
        }
    };
    
    useEffect(() => {
        if (isAuthenticated) {
            fetchProfileData();
            fetchLebenslaufData();
        }
    }, [isAuthenticated]);


    

    const handleChange = (e) => {
        const { name, value } = e.target;
    
        setProfileData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    
        if (name === 'miasto') {
            setMiasto(value);
        }
    };
    
    const handleSubmit = (e) => {
        e.preventDefault();
    
        const token = localStorage.getItem('access_token');
        if (!token) {
            setError('No access token');
            return;
        }
    
        // Check if items (education data) are filled
        if (items.length === 0) {
            setError('Education details are ');
            return;
        }
    
        const formData = new FormData();
        formData.append('profileData', JSON.stringify(profileData));
        formData.append('education', JSON.stringify(items.map(item => ({
            institution: item.institution,
            degree: item.degree,
            field_of_study: item.field_of_study,
            start_date: item.start_date,
            end_date: item.end_date
        }))));
        formData.append('experience', JSON.stringify(expItems.map(item => ({
            company: item.company,
            position: item.position,
            start_date: item.start_date,
            end_date: item.end_date
        }))));
        formData.append('skills', JSON.stringify(skillItems.map(item => ({
            title: item.title,
            level: item.level,
          
        }))));
        formData.append('languages', JSON.stringify(languageItems.map(item => ({
            name: item.name,
            proficiency: item.proficiency
        }))));
        if (selectedFile) {
            formData.append('img', selectedFile);
        }
        formData.append('phone', profileData.phone);
        formData.append('strasse', profileData.strasse);
        formData.append('haus_nr', profileData.haus_nr);
        formData.append('plz', profileData.plz);
        formData.append('driving', drivingLicense);
        formData.append('is_auto', isAuto);
        formData.append('miasto', profileData.miasto);
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/lebenslauf/create/`, formData, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            },
        })
        .then(response => {
            if (response.status === 201) {
                setCreatedId(response.data.id);
            } else {
                setError('Failed to create lebenslauf. Please try again later.');
            }
        })
        .catch(error => {
            console.error('Error submitting form:', error);
            setError(error.message || 'An error occurred');
        });
    };
    
    useEffect(() => {
        if (createdId) {
            navigate(`/cv/${createdId}`);
        }
    }, [createdId, navigate]);

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleDegreeChange = (e) => {
        setDegree(e.target.value);
    };

    const handleFieldOfStudyChange = (e) => {
        setFieldOfStudy(e.target.value);
    };

    const handleStartDateChange = (e) => {
        setStartDate(e.target.value);
    };

    const handleEndDateChange = (e) => {
        setEndDate(e.target.value);
    };

    const handleCompanyChange = (e) => {
        setCompany(e.target.value);
    };
    const handlePositionChange = (e) => {
        setPosition(e.target.value);
    };
    const handleExpStartDateChange = (e) => {
        setExpStartDate(e.target.value);
    };
    const handleExpEndDateChange = (e) => {
        setExpEndDate(e.target.value);
    };

    const handleSkillNameChange = (e) => {
        setSkillName(e.target.value);
    };
    const handleLevelChange = (e) => {
        setLevel(e.target.value);
    };
    const handleMiastoChange = (e) => {
        setMiasto(e.target.value);
    };

    const handleAddItem = () => {
        if (inputValue.trim() !== '' && degree.trim() !== '' && fieldOfStudy.trim() !== '' && startDate !== '' && endDate !== '') {
            const newItem = {
                institution: inputValue,
                degree: degree,
                field_of_study: fieldOfStudy,
                start_date: startDate,
                end_date: endDate
            };

            if (editingIndex !== null) {
                const updatedItems = [...items];
                updatedItems[editingIndex] = newItem;
                setItems(updatedItems);
                setEditingIndex(null);
            } else {
                setItems([newItem, ...items]);
            }

            setInputValue('');
            setDegree('');
            setFieldOfStudy('');
            setStartDate('');
            setEndDate('');
        }
    };

    const handleEditItem = (index) => {
        const item = items[index];
        setInputValue(item.institution);
        setDegree(item.degree);
        setFieldOfStudy(item.field_of_study);
        setStartDate(item.start_date);
        setEndDate(item.end_date);
        setEditingIndex(index);
    };

    const handleDeleteItem = (index) => {
        const updatedItems = items.filter((_, i) => i !== index);
        setItems(updatedItems);
    };

    const handleAddExpItem = () => {
        if (company.trim() !== '' && position.trim() !== '' && expStartDate !== '' && expEndDate !== '') {
            const newExpItem = {
                company: company,
                position: position,
                start_date: expStartDate,
                end_date: expEndDate
            };
    
            if (expEditingIndex !== null) {
                const updatedExpItems = [...expItems];
                updatedExpItems[expEditingIndex] = newExpItem;
                setExpItems(updatedExpItems);
                setExpEditingIndex(null);
            } else {
                setExpItems([newExpItem, ...expItems]);
            }
    
            setCompany('');
            setPosition('');
            setExpStartDate('');
            setExpEndDate('');
        }
    };
    
    const handleEditExpItem = (index) => {
        const item = expItems[index];
        setCompany(item.company);
        setPosition(item.position);
        setExpStartDate(item.start_date);
        setExpEndDate(item.end_date);
        setExpEditingIndex(index);
    };
    
    const handleDeleteExpItem = (index) => {
        const updatedExpItems = expItems.filter((_, i) => i !== index);
        setExpItems(updatedExpItems);
    };

    const handleAddSkillItem = () => {
        if (skillName.trim() !== '' && level.trim() !== '' ) {
            const newSkillItem = {
                title: skillName,
                level: level,
            
            };
    
            if (skillEditingIndex !== null) {
                const updatedSkillItems = [...skillItems];
                updatedSkillItems[skillEditingIndex] = newSkillItem;
                setSkillItems(updatedSkillItems);
                setSkillEditingIndex(null);
            } else {
                setSkillItems([newSkillItem, ...skillItems]);
            }
    
            setSkillName('');
            setLevel('');
       
        }
    };
    
    const handleEditSkillItem = (index) => {
        const item = skillItems[index];
        setSkillName(item.title);
        setLevel(item.level);
        setDescription(item.description);
        setSkillEditingIndex(index);
    };
    
    const handleDeleteSkillItem = (index) => {
        const updatedSkillItems = skillItems.filter((_, i) => i !== index);
        setSkillItems(updatedSkillItems);
    };

    const handleDrivingLicenseChange = (e) => {
        setDrivingLicense(e.target.value);
    };
    const handleLanguageNameChange = (e) => {
        setLanguageName(e.target.value);
    };
    
    const handleProficiencyChange = (e) => {
        setProficiency(e.target.value);
    };
    
    const handleAddLanguageItem = () => {
        if (languageName.trim() !== '' && proficiency.trim() !== '') {
            const newLanguageItem = {
                name: languageName,
                proficiency: proficiency,
            };
    
            if (languageEditingIndex !== null) {
                const updatedLanguageItems = [...languageItems];
                updatedLanguageItems[languageEditingIndex] = newLanguageItem;
                setLanguageItems(updatedLanguageItems);
                setLanguageEditingIndex(null);
            } else {
                setLanguageItems([newLanguageItem, ...languageItems]);
            }
    
            setLanguageName('');
            setProficiency('');
        }
    };
    
    const handleEditLanguageItem = (index) => {
        const item = languageItems[index];
        setLanguageName(item.name);
        setProficiency(item.proficiency);
        setLanguageEditingIndex(index);
    };
    
    const handleDeleteLanguageItem = (index) => {
        const updatedLanguageItems = languageItems.filter((_, i) => i !== index);
        setLanguageItems(updatedLanguageItems);
    };

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };
    const handleFileInputClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };


    if (authLoading) {
        return (
            <div className='w-100 d-flex justify-content-center h-100 ontop position-fixed t-0 cloude'>
                <img src='/img/logo.png' className='rotating-object align-self-center loader' alt="Loader" />
            </div>
        );
    }
    if (!hasSzukajacy && !hasObrotny) {
        return (
            <div>
               <div className='container vh10'>
                   <h2 className='text-center display-3'>
                        Musisz wykupić subskrypcję przynajmniej Szukający, aby móc utworzyć życiorys. 
                       <p className='text-center my-5'> <a href={`/profile/update/${profileData.slug}`}>Wykup subskrypcję</a></p>
                    </h2>
               </div>
            </div>
        );
    }
    
    return (
        <div>
            {!isAuthenticated ? (
                <h1>Nie jesteś zalogowany</h1>
            ) : (
                <>
                    {hasCv ? (
                        <div className='container vh10 d-flex align-items-center w-100 h-100'>
                         <div className='row w-100 d-flex justify-content-center'>
                         <div className='d-block my-5'>
                         <h2>
    {isTranslated ? 'Das Profil hat bereits einen Lebenslauf auf Deutsch erstellt' : 'Profil ma już wygenerowany życiorys po niemiecku'}
</h2>
<p>
    {isTranslated ? 'Im Rahmen Ihres Abonnements können Sie nur einen Lebenslauf erstellen' : 'W ramach Twojej subskrybcji możesz wygenerować tylko jeden życiorys'}
</p>
<p>
    {isTranslated ? 'Wir arbeiten daran, die Möglichkeit zu schaffen, mehr Dokumente zu erstellen' : 'Pracujemy nad możliwością generowania większej ilości dokumentów'}
</p>
<p className='purple my-5'>
    {isTranslated ? 'Melden Sie sich für den Newsletter an, und wir informieren Sie, wenn dies möglich ist' : 'Zapisz się do Newslettera, a poinformujemy Cię gdy będzie to możliwe'}
</p>
<NewsletterForm className='vh10' />

                                <NewsletterForm className='vh10'/>
                            </div>
                         </div>
                        </div>
                    ) : (
                        <Form onSubmit={handleSubmit}>
                            <Container className='vh10 lebens'>
                                <h2 className='my-5 display-3'>Stwórz polską wersję życiorysu</h2>
                                <div className='my-5'>
                                    <small className='text-danger'>w pakiecie Twojej subskrybcji masz możliwość jednorazowego wygenerowania życiorysu po niemiecku</small>
                                    <br/> 
                                    <small className='text-danger'>Przed naciśnięciem przycisku "wyślij" dokładnie sprawdź wszystkie dane!</small>
                                    {error && <p className="text-danger">{error}</p>}
                                </div>
                                <div className='row'>
                                    <div className='col-lg-2 ps-4 py-0 pe-0 d-flex bg-light justify-content-end'>
                                        <div className='d-block position-relative'
                                            onMouseEnter={handleMouseEnter}
                                            onMouseLeave={handleMouseLeave}
                                            onClick={handleFileInputClick}
                                            style={{ cursor: 'pointer' }}>
                                            <img src='logo/logo.png' className='w-100 bg-white' />
                                            {isHovered && (
                                                <div className='lebens-overlay position-absolute w-100 h-100 d-flex align-items-center justify-content-center' style={{ top: 0, left: 0, backgroundColor: 'rgba(0,0,0,0.5)', color: 'white' }}>
                                                    <h2>Zmień</h2>
                                                </div>
                                            )}
                                        </div>
                                        <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} />
                                    </div>
                                    <div className='col-lg-10 p-5 bg-body-tertiary'>
                                        <h2 className='display-3'>{profileData.name} {profileData.surname}</h2>
                                    </div>   
                                </div>
                            </Container>
                            <Container>
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <ul className='list-unstyled'>
                                            <li><h3 className='my-5'>Miejsce zamieszkania</h3></li>
                                            <li>
                                                <div className='row'>
                                                    <Form.Group controlId="formPlz" className='col-4'>
                                                        <Form.Label>Kod pocztowy</Form.Label>
                                                        <Form.Control 
                                                            type="text" 
                                                            name="plz" 
                                                            value={profileData.plz || ''} 
                                                            onChange={handleChange} 
                                                        />
                                                    </Form.Group>
                                                    <Form.Group controlId="formMiasto" className='col-8'>
                                                        <Form.Label>Miasto</Form.Label>
                                                        <Form.Control type="text" name="miasto" value={profileData.miasto || ''} onChange={handleChange} />
                                                    </Form.Group>
                                                </div>
                                            </li>
                                            <div className='d-flex gap-2 my-2'>
                                                <Form.Group controlId="formStrasse">
                                                    <Form.Label>Ulica</Form.Label>
                                                    <Form.Control type="text" name="strasse" value={profileData.strasse || ''} onChange={handleChange} />
                                                </Form.Group>
                                                <Form.Group controlId="formHaus">
                                                    <Form.Label>Nr</Form.Label>
                                                    <Form.Control type="text" name="haus_nr" value={profileData.haus_nr || ''} onChange={handleChange} />
                                                </Form.Group>
                                            </div>
                                            <li><h2>{profileData.email}</h2></li>
                                        </ul>
                                        <Form.Group controlId="formPhone">
                                            <Form.Label>Numer telefonu</Form.Label>
                                            <Form.Control type="text" name="phone" value={profileData.phone || ''} onChange={handleChange} />
                                        </Form.Group>
                                        <h3 className='my-5'>Kursy i certyfikaty</h3>
                                        <Form.Label className='mt-5'><strong>Prawo jazdy</strong></Form.Label><br/>
                                        <Form.Control as="select" name='driving' value={drivingLicense} onChange={handleDrivingLicenseChange} className='mb-5' >
                                            <option value="">Wybierz kategorię...</option>
                                            <option value='Nie'>Nie posiadam</option>
                                            <option value="A">A</option>
                                            <option value="A1">A1</option>
                                            <option value="A2">A2</option>
                                            <option value="AM">AM</option>
                                            <option value="B">B</option>
                                            <option value="B1">B1</option>
                                            <option value="BE">BE</option>
                                            <option value="C">C</option>
                                            <option value="C1">C1</option>
                                            <option value="C1E">C1E</option>
                                            <option value="CE">CE</option>
                                            <option value="D">D</option>
                                            <option value="D1">D1</option>
                                            <option value="D1E">D1E</option>
                                            <option value="DE">DE</option>
                                            <option value="T">T</option>
                                            <option value="L">L</option>
                                        </Form.Control>
                                        <Form.Group controlId="formIsAuto">
                                            <Form.Check type="checkbox" label="Posiadam samochód" checked={isAuto} onChange={(e) => setIsAuto(e.target.checked)} className='my-5' />
                                        </Form.Group>
                                        <div className='row'>
                                            <ul className="list-unstyled">
                                                {skillItems.map((item, index) => (
                                                    <li key={index} className='d-flex justify-content-between border-bottom-orange pb-4'>
                                                        <div className='d-block'>
                                                            <strong>{item.title}</strong><br />
                                                            {item.level}<br />
                                                        </div>
                                                        <div className='d-flex gap-2 p-5'>
                                                            <button type="button" onClick={() => handleEditSkillItem(index)} className='orange-button'><i className="bi bi-pencil"></i></button>
                                                            <button type="button" onClick={() => handleDeleteSkillItem(index)} className='orange-button'><i className="bi bi-trash3"></i></button>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                            <div className="input-group mb-3">
                                                <div className='col-7'>
                                                    <input type="text" className="form-control" placeholder="Dodaj pozycję do sekcji umiejętności: nazwa umiejętności" aria-label="Nazwa umiejętności" aria-describedby="button-addon2" value={skillName} onChange={handleSkillNameChange} />
                                                </div>
                                                <div className='col-7'>
                                                    <input type="text" className="form-control" placeholder="Poziom" aria-label="Poziom" value={level} onChange={handleLevelChange} />
                                                </div>
                                                <button className="btn btn-outline-success mx-2" type="button" id="button-addon2" onClick={handleAddSkillItem}>
                                                    <i className="bi bi-plus-circle"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <h3 className='my-5'>Języki</h3>
                                            <ul className="list-unstyled">
                                                {languageItems.map((item, index) => (
                                                    <li key={index} className='d-flex justify-content-between border-bottom-orange pb-4'>
                                                        <div className='d-block'>
                                                            <strong>{item.name}</strong><br />
                                                            {item.proficiency}
                                                        </div>
                                                        <div className='d-flex gap-2 p-5'>
                                                            <button type="button" onClick={() => handleEditLanguageItem(index)} className='orange-button'><i className="bi bi-pencil"></i></button>
                                                            <button type="button" onClick={() => handleDeleteLanguageItem(index)} className='orange-button'><i className="bi bi-trash3"></i></button>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                            <div className="input-group mb-3">
                                                <div className='col-7'>
                                                    <input type="text" className="form-control" placeholder="Dodaj język" aria-label="Język" aria-describedby="button-addon2" value={languageName} onChange={handleLanguageNameChange} />
                                                </div>
                                                <div className='col-7'>
                                                    <input type="text" className="form-control" placeholder="Poziom biegłości" aria-label="Poziom biegłości" value={proficiency} onChange={handleProficiencyChange} />
                                                </div>
                                                <button className="btn btn-outline-success mx-2" type="button" id="button-addon2" onClick={handleAddLanguageItem}>
                                                    <i className="bi bi-plus-circle"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-8'>
                                        <div className='row'>
                                            <h3>Edukacja</h3>
                                            <p className='text-danger my-5'>{isTranslated ? '*Denke daran, immer vom Ende her hinzuzufügen, das heißt, wenn dein letzter Abschluss das Studium war, trägst du es zuerst ein und gehst dann zurück zum Gymnasium. Selten verlangt der Arbeitgeber frühere Schulen.' : '*Pamiętaj, aby zawsze dodawać od końca, tzn. jeśli twoim ostatnim etapem były studia, to wpisujesz je najpierw i cofasz się do liceum. Rzadko kiedy pracodawca wymaga wcześniejszych szkół.'}</p>
                                            <ul className="list-unstyled">
                                                {items.map((item, index) => (
                                                    <li key={index} className='d-flex justify-content-between border-bottom-orange pb-4'>
                                                        <div className='d-block'>
                                                            <strong>{item.institution}</strong><br />
                                                            {item.degree}<br />
                                                            {item.field_of_study}<br />
                                                            {item.start_date} - {item.end_date}
                                                        </div>
                                                        <div className='d-flex gap-2 p-5'>
                                                            <button type="button" onClick={() => handleEditItem(index)} className='orange-button'><i className="bi bi-pencil"></i></button>
                                                            <button type="button" onClick={() => handleDeleteItem(index)} className='orange-button'><i className="bi bi-trash3"></i></button>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                            <div className="input-group mb-3">
                                                <div className='col-7'>
                                                    <input type="text" className="form-control" placeholder="Dodaj pozycję do sekcji edukacja: nazwa szkoły" aria-label="Nazwa instytucji" aria-describedby="button-addon2" value={inputValue} onChange={handleInputChange} />
                                                </div>
                                                <div className='col-7'>
                                                    <input type="text" className="form-control" placeholder="Stopień naukowy np. Licencjat, Magister, Średnie, Zawód" aria-label="Stopień naukowy" value={degree} onChange={handleDegreeChange} />
                                                </div>
                                                <div className='col-7'>
                                                    <input type="text" className="form-control" placeholder="Kierunek studiów, np. Filologia, Malarstwo, Ogólnokształcące" aria-label="Kierunek studiów" value={fieldOfStudy} onChange={handleFieldOfStudyChange} />
                                                </div>
                                                <div className='col-7'>
                                                    <input type="month" className="form-control" placeholder="Data rozpoczęcia" aria-label="Data rozpoczęcia" value={startDate} onChange={handleStartDateChange} />
                                                </div>
                                                <div className='col-7'>
                                                    <input type="month" className="form-control" placeholder="Data zakończenia" aria-label="Data zakończenia" value={endDate} onChange={handleEndDateChange} />
                                                </div>
                                                <button className="btn btn-outline-success mx-2" type="button" id="button-addon2" onClick={handleAddItem}>
                                                    <i className="bi bi-plus-circle"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <h3 className='my-5'>Doświadczenie zawodowe</h3>
                                            <p className='text-danger my-5'>{isTranslated ? '*Denke daran, immer vom Ende her hinzuzufügen, das heißt, wenn dein letzter Abschluss das Studium war, trägst du es zuerst ein und gehst dann zurück zum Gymnasium. Selten verlangt der Arbeitgeber frühere Schulen.' : '*Wpisuj miejsca pracy również od najbardziej aktualnego i cofaj się w przeszłość'}</p>
                                            <ul className="list-unstyled">
                                                {expItems.map((item, index) => (
                                                    <li key={index} className='d-flex justify-content-between border-bottom-orange pb-4'>
                                                        <div className='d-block'>
                                                            <strong>{item.company}</strong><br />
                                                            {item.position}<br />
                                                            {item.start_date} - {item.end_date}
                                                        </div>
                                                        <div className='d-flex gap-2 p-5'>
                                                            <button type="button" onClick={() => handleEditExpItem(index)} className='orange-button'><i className="bi bi-pencil"></i></button>
                                                            <button type="button" onClick={() => handleDeleteExpItem(index)} className='orange-button'><i className="bi bi-trash3"></i></button>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                            <div className="input-group mb-3">
                                                <div className='col-7'>
                                                    <input type="text" className="form-control" placeholder="Dodaj pozycję do sekcji doświadczenie zawodowe: nazwa firmy" aria-label="Nazwa firmy" aria-describedby="button-addon2" value={company} onChange={handleCompanyChange} />
                                                </div>
                                                <div className='col-7'>
                                                    <input type="text" className="form-control" placeholder="Stanowisko" aria-label="Stanowisko" value={position} onChange={handlePositionChange} />
                                                </div>
                                                <div className='col-7'>
                                                    <input type="month" className="form-control" placeholder="Data rozpoczęcia" aria-label="Data rozpoczęcia" value={expStartDate} onChange={handleExpStartDateChange} />
                                                </div>
                                                <div className='col-7'>
                                                    <input type="month" className="form-control" placeholder="Data zakończenia" aria-label="Data zakończenia" value={expEndDate} onChange={handleExpEndDateChange} />
                                                </div>
                                                <button className="btn btn-outline-success mx-2" type="button" id="button-addon2" onClick={handleAddExpItem}>
                                                    <i className="bi bi-plus-circle"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row my-5'>
                                    <button className='btn orange-button' type='submit'>
                                        wyślij
                                    </button>
                                </div>
                            </Container>
                        </Form>
                    )}
                </>
            )}
        </div>
    );
}

export default CreateLebenslauf;
