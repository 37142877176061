import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useTranslate } from '../config/translateContext';

function Register() {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const { isTranslated } = useTranslate();

    const handleRegistration = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/register/`, {
                username,
                email,
                password1,
                password2,
                first_name: firstName,
                last_name: lastName,
            });
            setMessage('Wiadomość e-mail z linkiem potwierdzającym została wysłana');
            console.log('Zarejestrowano pomyślnie:', response.data);
            // Czyszczenie formularza po udanej rejestracji
            setUsername('');
            setEmail('');
            setPassword1('');
            setPassword2('');
            setFirstName('');
            setLastName('');
            setErrors({});
        } catch (error) {
            if (error.response && error.response.data) {
                setErrors(error.response.data);
            } else {
                console.error('Nie udało się zarejestrować:', error);
            }
        }
    };

    return (
        <div className="container my-5">
            <h1>{isTranslated ? 'Registrieren' : 'Rejestracja'}</h1>
            {message && (
                <div className='info-message'>
                    <div className="info-message-text">{message}</div>
                </div>
            )}
            {errors && Object.keys(errors).length > 0 && (
                <div className="alert alert-danger">
                    {Object.values(errors).map((error, index) => (
                        <p key={index}>{error}</p>
                    ))}
                </div>
            )}
            <div className='row'>
                <div className='col-12 col-sm-12 col-md-6 offset-md-3 col-lg-6 offset-lg-3'>
                    <form onSubmit={handleRegistration}>
                        <div className="mb-3">
                            <label htmlFor="username" className="form-label">{isTranslated ? 'Benutzername' : 'Nazwa użytkownika'}:</label>
                            <input type="text" className="form-control" id="username" value={username} onChange={(e) => setUsername(e.target.value)} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="email" className="form-label">Email:</label>
                            <input type="email" className="form-control" id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="password1" className="form-label">{isTranslated ? 'Kennwort' : 'Hasło'}:</label>
                            <input type="password" className="form-control" id="password1" value={password1} onChange={(e) => setPassword1(e.target.value)} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="password2" className="form-label">{isTranslated ? 'Kennwort bestätigen' : 'Potwierdź hasło'}:</label>
                            <input type="password" className="form-control" id="password2" value={password2} onChange={(e) => setPassword2(e.target.value)} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="firstName" className="form-label">{isTranslated ? 'Vorname' : 'Imię'}:</label>
                            <input type="text" className="form-control" id="firstName" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="lastName" className="form-label">{isTranslated ? 'Nachname' : 'Nazwisko'}:</label>
                            <input type="text" className="form-control" id="lastName" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                        </div>
                        <div className="mb-3 form-check">
                            <input type="checkbox" className="form-check-input" id="termsCheckbox" required />
                            <label className="form-check-label" htmlFor="termsCheckbox">
                                {isTranslated ? 'Ich akzeptiere die Datenschutzrichtlinien und die Nutzungsbedingungen' : 'Akceptuję zasady Polityki Prywatności i Regulamin Użytkownika'}
                            </label>
                        </div>
                        <div className='w-100 d-flex justify-content-center my-5'>
                            <button type="submit" className="btn btn-primary">{isTranslated ? 'Registrieren' : 'Zarejestruj się'}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Register;
