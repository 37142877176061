import React, {useEffect, useState, useRef} from "react";
import { useParams } from "react-router-dom";

import { fadeAnimation } from '../animations/gsap';
import { useTranslate } from '../config/translateContext';
import { gsap } from 'gsap';
import { useLoading } from '../config/loadingContext';

import axios from "axios";

import moment from 'moment';

function Daten() {
    const [daten, setDaten] = useState(null);
    const [error, setError] = useState('');
    const { slug } = useParams();
    const { setIsLoading } = useLoading();
    const formattedDate = moment().format('YYYY-MM-DD');
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/daten/${slug}/`;
    // const apiUrl = `http://192.168.178.29:8000/Daten/${slug}`;
    console.log(apiUrl);
  
    useEffect(() => {
      setIsLoading(true);
  
      const fetchData = async () => {
        try {
          const response = await axios.get(apiUrl);
          setDaten(response.data.daten);
        } catch (error) {
          console.error("Failed to fetch data:", error);
          setError("Failed to load data.");
        } finally {
          setIsLoading(false);
        }
      };
  
      fetchData();
    }, [slug]);
  
    if (error) {
      return <p>Error: {error}</p>;
    }
  
    if (!daten) {
      return <p>Loading...</p>;
    }

    return (
      <div className="container  blue my-5" >
        <div className="row my-5">
        <h2 className="my- 5">DATENSCHUTZERKLÄRUNG</h2>
        </div>
        <div className="my-5 row">
    
       
        <div dangerouslySetInnerHTML={{ __html: daten.text }} className="my-5" />
        </div>
      </div>
    );
  }
  
  export default Daten;