  import axios from 'axios';
import React, { useEffect, useState, useContext } from 'react';
import axiosInstance from '../config/axiosConfig';
import { AuthContext } from '../config/AuthContext';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Accordion from 'react-bootstrap/Accordion';
import { useTranslate } from '../config/translateContext';
import Loader from './Loader';










const Forum = ({ slug }) => {
  const [forumData, setForumData] = useState(null);
  const [threads, setThreads] = useState([]);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [ws, setWs] = useState(null);
  const [selectedThreadId, setSelectedThreadId] = useState(null);
  const [validated, setValidated] = useState(false);
  const [show, setShow] = useState(false);
  const { user, isAuthenticated, logout, hasSzukajacy, hasObrotny } = useContext(AuthContext);
  const mediaUrl = process.env.REACT_APP_MEDIA_URL;
  const [activeComment, setActiveComment] = useState(null);
  const [commentInput, setCommentInput] = useState({});
  const [danger, setDanger] = useState(null);
  const { isTranslated } = useTranslate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [threadTitle, setThreadTitle] = useState({
    thread_title: '',
    add_thread: true,
  });
  const [loading, setLoading] = useState(true);

  const [regulamin, setRegulamin] = useState(null);

  
  const [translatedTexts, setTranslatedTexts] = useState({});

  const [dangerShow, setDangerShow] = useState(false);

  const handleDangerClose = () => setDangerShow(false);
  const handleDangerShow = () => setDangerShow(true);


  const [obrotni, setObrotni] = useState([]);



  const [forumDescription, setForumDescription] = useState('');
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear()).slice(-2);
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${day}.${month}.${year} ${hours}:${minutes}`;
  };

  const connectWebSocket = () => {
    const newWs = new WebSocket(`ws://localhost:8000/ws/forum/${slug}/`);

    newWs.onopen = () => {
      
      setWs(newWs);
    };

    newWs.onclose = () => {
      
      setTimeout(connectWebSocket, 1000); // Próba ponownego połączenia po 1 sekundzie
    };

    newWs.onmessage = (event) => {
      const message = JSON.parse(event.data);
      
      setMessages((prevMessages) => [...prevMessages, message]);
    };

    setWs(newWs);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(`/forum/${slug}/`);
        setForumData(response.data.forum);
        setThreads(response.data.threads);
        setForumDescription(response.data.forum.description);
        setObrotni(response.data.obrotni);
        setRegulamin(response.data.regulamin);
        console.log('isAuthenticated:', isAuthenticated);
        console.log('hasSzukajacy:', hasSzukajacy);
        console.log('hasObrotny:', hasObrotny);
       

      } catch (error) {
        console.error('Failed to fetch forum data:', error);
      }
    };
    setLoading(true);
    fetchData();
    connectWebSocket();
    setLoading(false);

    return () => {
      if (ws && ws.readyState === WebSocket.OPEN) {
        ws.close();
      }
    };
  }, [slug]);

  const fetchMessagesForThread = async (threadId) => {
    try {
      const response = await axiosInstance.get(`/forum/${slug}/`, {
        params: { thread: threadId },
      });
      setMessages(response.data.messages);
    } catch (error) {
      console.error('Failed to fetch messages:', error);
    }
  };

  const handleThreadSelection = (threadId) => {
    
    setLoading(true);
    setSelectedThreadId(threadId);
    fetchMessagesForThread(threadId);
    setLoading(false);
  };

  

  const sendMessage = async () => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      console.log('WebSocket is open, sending message...');
      ws.send(JSON.stringify({ content: input }));

      try {
        setLoading(true);
        const response = await axiosInstance.post(`/forum/${slug}/`, {
          content: input,
          thread_id: selectedThreadId,
        });

        
        fetchMessagesForThread(selectedThreadId);
        setLoading(false)
      } catch (error) {
        console.error('Failed to save message:', error);
      }

      setInput('');
    } else {
      console.error('WebSocket is not open. Cannot send message.');
      connectWebSocket(); // Reconnect WebSocket if closed
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setThreadTitle({
      ...threadTitle,
      [name]: value,
    });
  };

  const handleThreadSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      try {
        console.log('connect with server');
        const response = await axiosInstance.post(`/forum/${slug}/`, threadTitle);
        

        setThreads((prevThreads) => [...prevThreads, response.data]); // Dodaj nowy wątek do listy
        handleClose(); // Zamknij modal po dodaniu wątku
      } catch (error) {
        console.error('There was an error creating the thread:', error);
        if (error.response && error.response.status === 401) {
          logout(); // Wyloguj użytkownika, jeśli wystąpił błąd autoryzacji
        }
      }
    }

    setValidated(true);
    setLoading(false);
  };
  
  const handleCommentClick = (messageId) => {
    setActiveComment((prevActiveComment) => (prevActiveComment === messageId ? null : messageId));
  };
  
  const handleCommentInputChange = (messageId, value) => {
    setCommentInput((prev) => ({
      ...prev,
      [messageId]: value,
    }));
  };

  

  const sendComment = async (messageId) => {
    try {
      setLoading(true);
      const response = await axiosInstance.post(`/forum/${slug}/`, {
        comment_content: commentInput[messageId],
        message_id: messageId,
      });
      
      setCommentInput((prev) => ({
        ...prev,
        [messageId]: '',
      }));
      setActiveComment(null);

      fetchMessagesForThread(selectedThreadId); 
    } catch (error) {
      console.error('Failed to add comment:', error);
    }finally{
      setLoading(false);
    }
  };
  
  const handleReport = async (item, type) => {
    if (!item || !item.id || !item.content) {
      console.error('Invalid item data');
      return;
    }
  
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/report/`, {
        id: item.id,
        type: type, 
        content: item.content,
        created_by: item.created_by?.user?.username || 'Unknown User',
      });
      
      handleDangerShow(); // Show the modal after successfully sending the report
    } catch (error) {
      console.error('Failed to send report:', error);
    }
  };



  const translateTexts = async (texts, itemId) => {
    try {
        setLoading(true);

        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/translate/`, { texts: texts });

        const translatedTexts = response.data;

        setTranslatedTexts(prevTexts => ({
            ...prevTexts,
            [itemId]: {
                ...prevTexts[itemId],
                ...translatedTexts
            }
        }));
        
    } catch (error) {
        console.error("Translation error:", error);
    } finally {
        setLoading(false);
    }
};

const handleTranslateAll = (item, type) => {
    const texts = {};

    if (type === 'thread') {
        texts.thread = item.title;
    } else if (type === 'post') {
        texts.post = item.content;
    } else if (type === 'comment') {
        texts.comment = item.content;
    }

    translateTexts(texts, item.id);
};



if (loading) {
  return (
    <div className='w-100 d-flex justify-content-center h-100 ontop position-fixed t-0 cloude'>
      <img src='/img/logo.png' className='rotating-object align-self-center loader' alt="Loader" />
    </div>
  );
}

  return (






    <div className="forum">
      <h1>FORUM</h1>
  
      <div className="container">
        <div className="row">
          <div className="col-md-4 thread-list">
          <h3>{isTranslated ? 'Wählen Sie einen Thread aus' : 'Wybierz wątek'}</h3>

            <button className="purple btn" name="add_thread" onClick={handleShow}>
            <i className="bi bi-patch-plus"></i>{isTranslated ? 'Fügen Sie einen Thread hinzu' : 'Dodaj wątek'}

            </button>
            <div className="thread-list">





            {threads.slice().reverse().map((thread) => (
    <div  name="thread" className="thread-item">
        <p key={thread.id}
        onClick={() => handleThreadSelection(thread.id)} >{translatedTexts[thread.id]?.translated_thread || thread.title}</p>
        <Button className='ki' onClick={() => handleTranslateAll(thread, 'thread')}>KI Übersetzer <i class="bi bi-translate"></i></Button>
    </div>
))}







            </div>
          </div>
          <div className="col-md-8 discussion-window">
            <div className="discussion-content">
            {selectedThreadId ? (<>
              <div className="discussion-scroll">
                <div className="post glass">
                {messages.slice().reverse().map((msg, idx) => (
  <div key={idx}>
    <div className="post-header">
      <div className="post-author">
        <div className="col">
          {msg.created_by?.profilimg ? (
            <img src={`${mediaUrl}${msg.created_by.profilimg}`} alt="Profile" className='logo-forum'/>
          ) : (
            <img src="/img/logo.png" alt="Default Profile" />
          )}
        </div>
        <div className="col">
          <strong>{msg.created_by?.user?.username || 'Unknown User'}</strong>
        </div>
      </div>
      <span className="post-date">
        <em>({formatDate(msg.created_at)})</em>
        <button className='btn text-danger' name='danger'
            onClick={() => handleReport(msg, 'message')}><i class="bi bi-exclamation-lg text-danger"></i>{isTranslated ? 'Melden':'Zgłoś'}</button>
      </span>
    </div>
    <div className="post-content">
    <Button className='ki' onClick={() => handleTranslateAll(msg, 'post')}>KI Übersetzer <i class="bi bi-translate"></i></Button>
    
    <p key={msg.id}>{translatedTexts[msg.id]?.translated_post || msg.content}</p>


    <Accordion className='my-3'>
  <Accordion.Item eventKey="0">
    <Accordion.Header>
      {msg.comments && msg.comments.length > 0
        ? isTranslated
          ? 'Kommentare'
          : 'Komentarze'
        : isTranslated
        ? 'Kein Kommentaren'
        : 'Nie ma komentarzy'}
    </Accordion.Header>

    {msg.comments && msg.comments.length > 0 && (
      <Accordion.Body>
        {msg.comments.slice().reverse().map((comment, idx) => (
          <div key={idx} className="comment">
            <div className="col">

              <p className='purple'><strong>{comment.created_by}:</strong></p>
              <p key={comment.id}>{translatedTexts[comment.id]?.translated_comment || comment.content}</p>
            </div>
            <div className="col text-end">
              <button className="btn text-danger" name="danger" onClick={() => handleReport(comment, 'comment')}>
                <i className="bi bi-exclamation-lg text-danger"></i>
                {isTranslated ? 'Melden' : 'Zgłoś'}
              </button>
            </div>
            <Button className="ki purple" onClick={() => handleTranslateAll(comment, 'comment')}>
                KI Übersetzer <i className="bi bi-translate"></i>
              </Button>
             
          </div>
        ))}
      </Accordion.Body>
    )}
  </Accordion.Item>
</Accordion>
{activeComment === msg.id && (
        <div className="comment-input">
          <textarea
            value={commentInput[msg.id] || ''}
            onChange={(e) => handleCommentInputChange(msg.id, e.target.value)}
            rows="2"
            className="form-control"
            placeholder="Wpisz komentarz"
          ></textarea>
          <button
            className="btn btn-primary mt-2"
            onClick={() => sendComment(msg.id)}
          >
            Wyślij
          </button>
        </div>
      )}
      <button 
        className='bg-transparent ki'
        onClick={() => handleCommentClick(msg.id)}>
        <small className='purple my-3'><i className="bi bi-patch-plus"> Skomentuj</i></small>
      </button>

   
    </div>
  </div>
))}


                </div>

              </div>


              <div className="reply-form">
        <textarea
            value={input}
            onChange={(e) => setInput(e.target.value)}
            rows="2"
        ></textarea>
        <button onClick={sendMessage}>
            {isTranslated? 'Senden':'Wyślij'}<i className="bi bi-send"></i>
        </button>
    </div>
   
                        </>
                  ) : (
                    <div className="forum-description">
                      <p>{forumDescription}</p>
                    </div>
                  )}
                  
            </div>

          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
        <Modal.Title>{isTranslated ? 'Thema im Forum hinzufügen' : 'Dodaj wątek do forum'}</Modal.Title>

        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleThreadSubmit}>
            <Row className="mb-5">
              <Form.Group as={Col} md="12" controlId="validationCustom01">
              <Form.Label>{isTranslated ? 'Thema benennen' : 'Zatytułuj wątek'}</Form.Label>

                <Form.Control required type="text" placeholder="..." name="thread_title" onChange={handleChange} />
              </Form.Group>
            </Row>
            <Button type="submit" className="text-white">
  {isTranslated ? 'Hinzufügen' : 'Dodaj'}
</Button>

          </Form>
        </Modal.Body>
      </Modal>





      <Modal show={dangerShow} onHide={handleDangerClose} centered>
        <Modal.Header closeButton>
        <Modal.Title>{isTranslated ? 'Danke für Ihre Wachsamkeit!' : 'Dziękujemy za czujność!'}</Modal.Title>

        </Modal.Header>
        <Modal.Body>{isTranslated ? 'Wir werden prüfen, ob dieser Kommentar gegen die Regeln verstößt!' : 'Sprawdzimy czy ten komentarz narusza zasady!'}</Modal.Body>

   
      </Modal>
      <div className='row'>
        <div className='col-md-12'>
          <p>  <strong className='text-danger'>
    {isTranslated 
      ? 'Mit Ihrer Teilnahme an der Diskussion im Forum akzeptieren Sie die Regeln!' 
      : 'Biorąc udział w dyskusji na forum akceptujesz jego regulamin!'}
  </strong></p>
        <Accordion>
      <Accordion.Item eventKey="3">
        <Accordion.Header>
  {isTranslated ? 'Forumsregeln' : 'Regulamin forum'}
</Accordion.Header>

        <Accordion.Body>
    
        {regulamin ? (
      <div className='animatable my-5' dangerouslySetInnerHTML={{ __html: regulamin.text }} />
    ) : (
      <p>Ładowanie regulaminu...</p> // Możesz wyświetlić komunikat ładowania
    )}
            </Accordion.Body>
      </Accordion.Item>
    
    </Accordion>
        </div>
      </div>
    </div>
  );
};

export default Forum;
