import { useEffect } from "react";
import { useAuth } from "../config/AuthContext";
import axiosInstance from "../config/axiosConfig";
import Loader from "../components/Loader";

export const Logout = () => {
    const { logout } = useAuth();  // Use logout function from AuthContext

    useEffect(() => {
        (async () => {
            try {
                const refreshToken = localStorage.getItem('refresh_token');
                if (!refreshToken) {
                    throw new Error("No refresh token found");
                }

                await axiosInstance.post(
                    `${process.env.REACT_APP_API_BASE_URL}/logout/`,
                    { refresh_token: refreshToken },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
                        },
                        withCredentials: true
                    }
                );

                // Use the logout function from AuthContext
                logout();
                window.location.href = '/login';
            } catch (e) {
                console.log('Logout not working', e);
                // Still logout even if API call fails
                logout();
                window.location.href = '/login';
            }
        })();
    }, [logout]);

    return (
        <div><Loader /></div>
    );
};

export default Logout;
