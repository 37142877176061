import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import { useTranslate } from './../config/translateContext';
import { useAuth } from '../config/AuthContext';
import Forum from '../components/forum';
import { useNavigate, Link } from 'react-router-dom';

const LandDetail = () => {
  const { slug } = useParams();
  const [land, setLand] = useState(null);
  const [error, setError] = useState(null);
  const [slup, setSlup] = useState(null);
  const [reklamaSlup, setReklamaSlup] = useState([]);
  const [diameter, setDiameter] = useState(window.innerWidth * (window.innerWidth > 700 ? 0.4 : 1.8));
  const [showModal, setShowModal] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const { isTranslated } = useTranslate();
  const radius = diameter / 2; 
  const [cities, setCities] = useState([]);
  const { isAuthenticated, user, token } = useAuth();
  const [selectedThreadId, setSelectedThreadId] =useState(null);
  const [threads, setThreads] = useState([]);
  const [obrotni, setObrotni] = useState([]);
  const [work, setWork] = useState([]);

  const mediaUrl = process.env.REACT_APP_MEDIA_URL;



  const handleThreadSelection = (threadId) => {
    setSelectedThreadId(threadId);
  };
  useEffect(() => {
    fetchLandData();
  
  }, [slug]);

  
  const fetchLandData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/landy/${slug}/`);
      setLand(response.data.land);
      setSlup(response.data.slup);
      setReklamaSlup(response.data.reklama);
      setCities(response.data.cities);
      setThreads(response.data.threads);
      setObrotni(response.data.obrotni);
      setWork(response.data.work);
      
    } catch (error) {
      setError(`Error fetching profile data: ${error.response ? error.response.statusText : error.message}`);
    } 
  };



  
  const handleImageClick = (src, e) => {
    e.stopPropagation();
    e.preventDefault();
    setCurrentImage(src);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleResize = () => {
    setDiameter(window.innerWidth * (window.innerWidth > 700 ? 0.2 : 0.2));
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!land) {
    return null; // Optionally render nothing or a loading indicator
  }

  return (
    <>
      <h1 className='vh15'>{land.name}</h1>
      <div className="slup vh15">
        <img src={`${process.env.PUBLIC_URL}/img/slup.png`} className="central-image" alt="Central" />
        <div id="spin-container" className="spin-container">
          {reklamaSlup.map((item, index) => (
            <div
              key={index}
              className="orbit-image-container"
              style={{
                transform: `rotateY(${index * (360 / reklamaSlup.length)}deg) translateZ(${radius}px)`,
                position: 'absolute',
                top: '50%',
                left: '50%',
                transformOrigin: '0 0',
                width: '300px',
                height: '900px',
              }}
            >
              <img
                src={`${process.env.REACT_APP_API_BASE_URL}${item.image}`}
                alt={`img-${index}`}
                onClick={(e) => handleImageClick(`${process.env.REACT_APP_API_BASE_URL}${item.image}`, e)}
                style={{
                  cursor: 'pointer',
                  transition: 'transform 0.5s',
                  width: '60%',
                  height: 'auto',
                }}
                loading='lazy'
              />
            </div>
          ))}
        </div>
      </div>
      
      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Body>
          <img src={currentImage} alt="Preview" style={{ width: '100%' }} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className='text-white' onClick={handleClose}>
            {isTranslated ? 'Schließen' : 'zamknij'}
          </Button>
        </Modal.Footer>
      </Modal>
      
      <div className='container'>
        <h2>Wybierz miasto powiatowe</h2>
        <ul className='list-inline'>
          {cities.map((city, index) => (
            <li key={index} className="list-inline-item">
   <h3>          <Link to={`/city/${city.slug}/`}>{city.name}</Link>       </h3>
            </li>
          ))}
        </ul>
      </div>

      {/* Forum section */}
      <div className="container">

        {error && <div className="alert alert-danger">{error}</div>}

        <div className='container nowi'>
   <h1>NOWI OBROTNI</h1>
    <div className='container obrotni-all'>
        <div className='scroll-container'>
            {obrotni.map((profil, index) => (
                <div className='card neumorphism' key={profil.id}>
                  <div className='card-image'>
                  <img src={`${mediaUrl}${profil.profilimg}`} className="card-img-top" alt={profil.title} />
                    </div>
                    <div className='card-category-obrotni'>
                        <strong>{profil.job ? profil.job.name : profil.name}</strong>
                    </div>
                    <div className='card-body'>
                        <h5 className='card-title'>
                            {profil.title}
                        </h5>
                        <p className='card-text'>
                            {profil.intro}
                        </p>
                        <Link to={`/profile/${profil.slug}/`}>Odwiedź profil</Link>
                    </div>
                </div>
            ))}
        </div>
    </div>
</div>


            {/* work advertisement */}

            {work && work.length > 0 ? (
    <div className='container vh30'>
        <h1>PRACA</h1>
        <div className='container work-all my-5'>
            <div className='scroll-container'>
                {work.map((adv, index) => (
                    <div className='card neumorphism' key={adv.id}>
                        <img src={`${mediaUrl}${adv.img}`} className="card-img-top" alt={adv.title} />
                        <div className='card-category-work'>
                            <strong>{adv.city.name}</strong>
                        </div>
                        <div className='card-body'>
                            <h5 className='card-title'>
                            {isTranslated ? adv.job.name_de : adv.job.name}
                            </h5>
                            <Link to={`/praca/${adv.id}/`}>
                  {isTranslated ? 'Lesen' : 'Przeczytaj'}
                </Link>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </div>
) : null}


<Forum slug={slug}/>




      </div>

      <style jsx>{`
        .forum-board {
          margin: 20px;
        }

        .message-list {
          margin-bottom: 20px;
        }

        .message-card {
          background-color: #f8f9fa;
          border: 1px solid #ddd;
          padding: 15px;
          margin-bottom: 10px;
          border-radius: 5px;
        }

        .message-header {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
        }

        .message-content {
          font-size: 16px;
          line-height: 1.5;
        }

        .message-date {
          font-size: 12px;
          color: #666;
        }

        .message-form {
          margin-top: 20px;
        }

        .alert {
          margin-bottom: 20px;
        }

        /* Styles for screens greater than 700px */
        @media (min-width: 700px) {
          .slup {
            position: relative;
            width: ${diameter}px;
            height: ${diameter}px;
            margin: auto;
            perspective: 1500px;
            max-height:700px;
          }
          .central-image {
            position: absolute;
            width: 250px;
            height: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
          .spin-container {
            position: absolute;
            width: 100%;
            height: 100%;
            top: -25vh;
            left: 0%;
            transform-style: preserve-3d;
            animation: spin 20s infinite linear;
            transform: translate(-50%, -50%);
          }
          .spin-container:hover {
            animation-play-state: paused;
          }
          .spin-container img {
            transition: transform 0.5s;
            backface-visibility: hidden;
            margin-top: -20vh;
          }
          @keyframes spin {
            from {
              transform: rotateY(0deg);
            }
            to {
              transform: rotateY(-360deg);
            }
          }
          .orbit-image-container {
            transform-origin: 50% 50%;
          }
          .small-padding {
            min-height:100vh;
            margin-top:15vh;
          }
        }

        /* Styles for screens less than or equal to 700px */
        @media (min-width: 700px) {
          .slup {
            position: relative;
            width: ${diameter}px;
            height: ${diameter}px;
            margin: auto;
            perspective: 1500px;
            perspective-origin: center;
          }
          .central-image {
            position: absolute;
            width: 250px;
            height: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
          .spin-container {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            transform-style: preserve-3d;
            animation: spin 20s infinite linear;
          }
          .spin-container:hover {
            animation-play-state: paused;
          }
          .orbit-image-container {
            position: absolute;
            top: 50%;
            left: 50%;
            transform-origin: center;
            transform-style: preserve-3d;
          }
          .spin-container img {
            transition: transform 0.5s;
            backface-visibility: hidden;
            margin-top: -20vh;
          }
          @keyframes spin {
            from {
              transform: rotateY(0deg);
            }
            to {
              transform: rotateY(-360deg);
            }
          }
          .small-padding {
            min-height: 100vh;
            margin-top: 15vh;
          }
        }

        @media (max-width: 700px) {
          .small-padding {
            min-height: 100vh;
            margin-top: 15vh;
          }
          .slup {
            position: relative;
            width: 40vw;
            height: 100vw;
            margin: auto;
            perspective: 1500px;
            perspective-origin: center;
          }
          .central-image {
            position: absolute;
            width: 100%;
            height: 500px;
            top: 57%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
          .spin-container {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            transform-style: preserve-3d;
            animation: spin 20s infinite linear;
          }
          .spin-container:hover {
            animation-play-state: paused;
          }
          .orbit-image-container {
            position: absolute;
            top: 50%;
            left: 50%;
            transform-origin: center;
            transform-style: preserve-3d;
          }
          .spin-container img {
            transition: transform 0.5s;
            backface-visibility: hidden;
            margin-top: -20vh;
          }
          @keyframes spin {
            from {
              transform: rotateY(0deg);
            }
            to {
              transform: rotateY(-360deg);
            }
          }
        }
      `}</style>
    </>
  );
};

export default LandDetail;
