import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useTranslate } from '../config/translateContext';
import { useAuth } from '../config/AuthContext';

function MyNavbar() {
    const { isTranslated, toggleTranslate } = useTranslate();
    const { isAuthenticated, logout, user } = useAuth();
    const [expanded, setExpanded] = useState(false);
    const navigate = useNavigate();  
    const { slug } = useParams();

    const handleProfileClick = () => {
        const profileSlug = user?.profile_slug || user?.slug;
        console.log('Profile slug on click:', profileSlug);
        if (profileSlug) {
            navigate(`/profile/update/${profileSlug}`);
        } else {
            console.log('No profile slug available');
        }
    };
    
    const handleMessageClick = () => {
        const profileSlug = user?.profile_slug || user?.slug;
        console.log('Profile slug on click:', profileSlug);
        if (profileSlug) {
            navigate(`/profile/${profileSlug}/contact`);
        } else {
            console.log('No profile slug available');
        }
    };
    
    const mainLinks = [
        { id: "obrotni", path: "/project", name: "Obrotni", nameDe: "Obrotni", className: 'text-body' },
        { id: "usługi", path: "/landy", name: "Usługi", nameDe: "Dienstleistungen", className: 'text-danger' },
        { id: "infoteka", path: "/infoteka", name: "Infoteka", nameDe: "Infotheke", className: 'text-warning' },
        
        { id: "praca", path: "/praca", name: "Praca", nameDe: "Arbeit", className: 'orange' },
    ];

    const userLinksIsAuth = [
        { 
            id: "profile", 
            name: <i className="bi bi-gear"></i>, 
            nameDe: <i className="bi bi-gear"></i>, 
            className: 'purple',
            onClick: handleProfileClick,
            style: { cursor: 'pointer' }
        },
        { 
            id: "messages", 
            name: <i className="bi bi-chat"></i>, 
            nameDe:<i className="bi bi-chat"></i>, 
            className: 'purple',
            onClick: handleMessageClick,
            style: { cursor: 'pointer' }
        },
        { 
            id: "warnings", 
            name:<i className="bi bi-exclamation-lg"></i>, 
            nameDe:<i className="bi bi-exclamation-lg"></i>, 
            className: 'purple',
            onClick: handleProfileClick,
            style: { cursor: 'pointer' }
        },
        { 
            id: "sources", 
            name:<i className="bi bi-suit-heart"></i>, 
            nameDe:<i className="bi bi-suit-heart"></i>, 
            className: 'purple',
            onClick: handleProfileClick,
            style: { cursor: 'pointer' }
        },
        { 
            id: "logout", 
            path: "/logout", 
            name: "Wyloguj się", 
            nameDe: "Abmelden", 
            className: 'purple',
            onClick: () => {
                logout();
                navigate('/login');  // Ensure user is redirected to login page after logout
            }
        },
      
    ];

    const userLinksNotAuth = [
        { id: "login", path: "/login", name: "Zaloguj się", nameDe: "Anmelden", className: 'purple' },
        { id: "register", path: "/register", name: "Zarejestruj się", nameDe: "Registrieren", className: 'purple' },
    ];

    const renderLink = useCallback((link) => {
        if (link.id === "profile" || link.id === "messages") {
            return (
                <Nav.Link
                    key={link.id}
                    as="span"
                    className={link.className}
                    onClick={() => {
                        setExpanded(false);
                        link.onClick();
                    }}
                >
                    {isTranslated ? link.nameDe : link.name}
                </Nav.Link>
            );
        }

        return (
            <Nav.Link
                key={link.id}
                as={Link}
                to={link.path}
                className={link.className}
                onClick={() => setExpanded(false)}
            >
                {isTranslated ? link.nameDe : link.name}
            </Nav.Link>
        );
    }, [isTranslated]);

    return (
        <>
            <Navbar expand="lg" className="bg-white position-absolute w-100 top-0 mt-0 pt-0" expanded={expanded} >
                <Container fluid className='pt-3'>
                    <Navbar.Brand as={Link} to="/">
                        <img src='/img/logo.png' alt="logo" className='img-fluid logo' />
                    </Navbar.Brand>
                    
                    <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(!expanded)} />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            {mainLinks.map(renderLink)}
                        </Nav>
                        <Nav>
                        <button className="btn gradient-text" onClick={toggleTranslate}>
                {isTranslated ? 'Przetłumacz na polski' : 'Übersetzen der Seitenstruktur ins Deutsch'}
            </button>
                            {isAuthenticated ? userLinksIsAuth.map(renderLink) : userLinksNotAuth.map(renderLink)}
                        </Nav>
                          
                    </Navbar.Collapse>
                    
                </Container>
        
            </Navbar>
       
        </>
    );
}

export default MyNavbar;
