import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Search } from 'react-bootstrap-icons';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Form from 'react-bootstrap/Form';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { useTranslate } from '../config/translateContext';
import NewsletterForm from '../components/newsletter';
import { useAuth } from '../config/AuthContext';
import InputGroup from 'react-bootstrap/InputGroup';


function WorkList() {
  const [data, setData] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { isTranslated } = useTranslate();
  const mediaUrl = process.env.REACT_APP_MEDIA_URL;
  const [searchQuery, setSearchQuery] = useState('');
  const menuRef = useRef(null);
  const [lands, setLands] = useState([]);
  const [cities, setCities] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedLand, setSelectedLand] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [jobQueriesPl, setJobQueriesPl] = useState([]);
  const [jobQueriesDe, setJobQueriesDe] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const { isAuthenticated, loading: authLoading, hasObrotny } = useAuth();

  const fetchLandsAndCitiesAndCategories = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/praca/`);
      setLands(response.data.land);
      setCities(response.data.city);
      setCategories(response.data.category);
      setFilteredCategories(response.data.category);
    } catch (error) {
      console.error('Error fetching lands, cities, and categories:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
setLoading(true);
    fetchLandsAndCitiesAndCategories();
    fetchData();
  }, [fetchLandsAndCitiesAndCategories]);

  const fetchData = useCallback(async (searchQuery = '', land = '', city = '', category = '', job = '') => {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/praca`, {
        params: { search: searchQuery, land, city, category, job },
      });
      console.log('Fetched data:', response.data); // Logowanie danych
  
      if (job) {
        setSelectedJob(job);
        setJobQueriesPl(response.data.job_queries_pl);
        setJobQueriesDe(response.data.job_queries_de);
        setData([]); // Ustawienie pustej listy ogłoszeń
      } else {
        setSelectedJob(null);
        setData(response.data.work_advertisements); // Ustawienie wszystkich ogłoszeń
        setJobQueriesPl([]);
        setJobQueriesDe([]);
      }
  
      if (land) {
        setCities(response.data.queried_cities);
      } else {
        setCities(response.data.city);
      }
  
      if (city) {
        setFilteredCategories(response.data.queried_category);
      } else {
        setFilteredCategories([]);
      }
  
      if (category) {
        setJobs(response.data.queried_jobs);
      } else {
        setJobs([]);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, []);
  

  const handleSearchChange = useCallback((e) => {
    setSearchQuery(e.target.value);
  }, []);

  const handleSearchSubmit = useCallback(async (e) => {
    e.preventDefault();
    setLoading(true);
    fetchData(searchQuery, selectedLand, selectedCity, selectedCategory);
  }, [fetchData, searchQuery, selectedLand, selectedCity, selectedCategory]);

  const handleLandChange = useCallback(async (slug) => {
    setSelectedLand(slug);
    setSelectedCity('');
    setSelectedCategory('');
    fetchData(searchQuery, slug, '', '');
  }, [fetchData, searchQuery]);

  const handleCityChange = useCallback((cityId) => {
    setSelectedCity(cityId);
    setSelectedCategory('');
    fetchData(searchQuery, selectedLand, cityId, '');
  }, [fetchData, searchQuery, selectedLand]);

  const handleCategoryChange = useCallback((categoryId) => {
    setSelectedCategory(categoryId);
    fetchData(searchQuery, selectedLand, selectedCity, categoryId);
  }, [fetchData, searchQuery, selectedLand, selectedCity]);

  const handleJobClick = useCallback((jobId) => {
    fetchData('', '', '', '', jobId);
  }, [fetchData]);

  const scroll = useCallback((direction) => {
    const { current } = menuRef;
    const scrollAmount = 200;

    if (direction === 'left') {
      current.scrollBy({ left: -scrollAmount, top: 0, behavior: 'smooth' });
    } else {
      current.scrollBy({ left: scrollAmount, top: 0, behavior: 'smooth' });
    }
  }, []);

  const JobList = ({ jobs, onJobClick }) => (
    <div className='row my-5 py-5 job-menu'>
      <div className="menu-wrapper col-md-12 col-lg-12">
        <span className="pointer left-pointer" onClick={() => scroll('left')}>
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#fd7e14" className="bi bi-chevron-left" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"/>
          </svg>
        </span>
        <div className="menu-item" ref={menuRef}>
          {jobs.map((job, index) => (
            <Link className='p-2' key={index} to="#" onClick={() => onJobClick(job.id)}>
              <span>{isTranslated ? job.name_de : job.name}</span>
            </Link>
          ))}
        </div>
        <span className="pointer right-pointer" onClick={() => scroll('right')}>
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#fd7e14" className="bi bi-chevron-right" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
          </svg>
        </span>
      </div>
    </div>
  );

  const memoizedJobList = useMemo(() => {
    return selectedCategory && <JobList jobs={jobs} onJobClick={handleJobClick} />;
  }, [selectedCategory, jobs, isTranslated, handleJobClick]);


  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className='container vh10 work'>
      <div className='row'>
        <h1>{isTranslated ? 'Arbeitsmarkt' : 'Praca'}</h1>
        <div className='row mt-2'>
          <div className='d-flex gap-2'>
            {isAuthenticated && hasObrotny ? (
              <Button as={Link} to="/create-ad" className="">
                {isTranslated ? 'Anzeige erstellen' : 'Utwórz ogłoszenie'}
              </Button>
            ) : (
              <Button className="" disabled>
                {isTranslated ? 'Anzeige erstellen' : 'Utwórz ogłoszenie'}
              </Button>
            )}
            {isAuthenticated && hasObrotny ? (
              <Button as={Link} to="/create-cv" className=" orange-button">
                {isTranslated ? 'Anzeige erstellen' : 'Utwórz Lebenslauf'}
              </Button>
            ) : (
              <Button className="bg-orange" disabled>
                {isTranslated ? 'Anzeige erstellen' : 'Utwórz Lebenslauf'}
              </Button>
            )}
          </div>
        </div>
      </div>

      <nav className="navbar navbar-expand-lg vh10 navbar-extra">
        <div className="container">
          <div className="row w-100 align-items-center">
            <div className="col-md-6 d-flex justify-content-around">
            <DropdownButton id="dropdown-basic-button" 
            className='drop-small'
            title={isTranslated ? "Wählen Sie Land" : "Wybierz Land"}>
  {lands.map((land) => (
    <Dropdown.Item key={land.id} onClick={() => handleLandChange(land.slug)}>
      {isTranslated ? land.name_de : land.name}
    </Dropdown.Item>
  ))}
</DropdownButton>

{selectedLand && (
  <DropdownButton id="dropdown-basic-button" 
  className='drop-small'
  title={isTranslated ? "Wählen Sie Stadt" : "Wybierz Miasto"}>
    {cities.map((city) => (
      <Dropdown.Item key={city.id} onClick={() => handleCityChange(city.id)}>
        {city.name}
      </Dropdown.Item>
    ))}
  </DropdownButton>
)}

{selectedCity && (
  <DropdownButton 
  className='drop-small'
  id="dropdown-basic-button" title={isTranslated ? "Wählen Sie Kategorie" : "Wybierz Kategorię"}>
    {filteredCategories.map((category) => (
      <Dropdown.Item key={category.id} onClick={() => handleCategoryChange(category.id)}>
        {category.name}
      </Dropdown.Item>
    ))}
  </DropdownButton>
)}

            </div>
            <div className="col-md-6 col-sm-12 col-xs-12 col-12 form-container clearfix">
              <Form className='d-flex align-items-center' onSubmit={handleSearchSubmit}>
              <InputGroup>
     
              <Form.Control
                  type="text"
                  placeholder={isTranslated ? 'sucht' : 'szukaj'}
                  className="form-control me-2 neumorphic-input"
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
               <InputGroup.Text type="submit" id="btnGroupAddon2"><Search size={20} /></InputGroup.Text>
        </InputGroup>
       
               
              </Form>
            </div>
          </div>
        </div>
      </nav>

      {memoizedJobList}
      <div className='row vh10 work-row'>
        {(selectedJob ? jobQueriesPl : data).map((item, index) => (
          <div className='col-12 col-sm-12 col-md-6 col-lg-4 my-5' key={index}>
            <div className='card neumorphism'>
              {item.img ? (
                <img src={`${mediaUrl}${item.img}`} className="card-img-top" alt={item.title} />
              ) : (
                <div className="placeholder-img">Brak obrazka</div>
              )}
              <div className='card-category-work'>
                <strong>{item.city?.name}</strong>
              </div>
              <div className='card-body'>
                <h5 className='card-title'>{item.name}<br /></h5>
                <p className='my-5' key={item.id} dangerouslySetInnerHTML={{ __html: item.description_pl }} />
                <Link to={`/praca/${item.id}/`}>
                  {isTranslated ? 'Lesen' : 'Przeczytaj'}
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default WorkList;
