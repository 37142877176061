import React, {useEffect, useState, useRef} from "react";
import { useParams } from "react-router-dom";

import { fadeAnimation } from '../animations/gsap';
import { useTranslate } from '../config/translateContext';
import { gsap } from 'gsap';
import { useLoading } from '../config/loadingContext';

import axios from "axios";





function Agd() {
    const [agd, setAgd] = useState(null);
    const [error, setError] = useState('');
    const { slug } = useParams();
    const { setIsLoading } = useLoading();
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/agd/${slug}/`;
    // const apiUrl = `http://192.168.178.29:8000/Daten/${slug}`;
    console.log(apiUrl);
  
    useEffect(() => {
      setIsLoading(true);
  
      const fetchData = async () => {
        try {
          const response = await axios.get(apiUrl);
          setAgd(response.data.agd);
        } catch (error) {
          console.error("Failed to fetch data:", error);
          setError("Failed to load data.");
        } finally {
          setIsLoading(false);
        }
      };
  
      fetchData();
    }, [slug]);
  
    if (error) {
      return <p>Error: {error}</p>;
    }
  
    if (!agd) {
      return <p>Loading...</p>;
    }

    return (
      <div className="container  blue my-5" >
        <div className="row my-5">
        <h2 className="my- 5">Agd</h2>
        </div>
        <div className="my-5 row">

        <div dangerouslySetInnerHTML={{ __html: agd.text }} className="my-5" />
        </div>
      </div>
    );
  }
  
  export default Agd;