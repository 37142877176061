import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { Container, Row, Col, Image, Spinner, Alert, Button, Modal, Form } from 'react-bootstrap';
import { useAuth } from '../config/AuthContext';
import { useTranslate } from '../config/translateContext';


function WorkAdvertisementDetail() {
  const { pk } = useParams();
  const [ad, setAd] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [show, setShow] = useState(false);
  const [pdfFile, setPdfFile] = useState(null);
  const [myPdf, setMyPdf] = useState(false);
  const { isTranslated } = useTranslate();
  const { isAuthenticated, loading: authLoading, hasObrotny, hasSzukajacy } = useAuth();
  const [oryginal, setOryginal] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/praca/${pk}/`)
      .then(response => {
        setAd(response.data);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [pk]);

  const handleFileChange = (event) => {
    setPdfFile(event.target.files[0]);
  };

  const handleCheckboxChange = () => {
    setMyPdf(!myPdf);
  };

  const handleApply = () => {
    if (!isAuthenticated) {
        alert('Musisz być zalogowany, aby zaaplikować.');
        return;
    }
    if (!hasObrotny && !hasSzukajacy) {
        alert('Musisz posiadać subskrybcję co najmniej Szukającego, aby zaaplikować.');
        return;
    }

    const formData = new FormData();
    if (pdfFile) formData.append('pdf_file', pdfFile);
    formData.append('my_pdf', myPdf);

    const token = localStorage.getItem('access_token');

    axios.post(`${process.env.REACT_APP_API_BASE_URL}/praca/${pk}/`, formData, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
        },
    })
    .then(response => {
        console.log('Application sent successfully');
        navigate('/praca/bewerbung/success');
        handleClose();
    })
    .catch(error => {
        if (error.response) {
            // Sprawdź czy jest to błąd 400 z komunikatem, że użytkownik już zaaplikował lub nie ma Lebenslauf
            if (error.response.status === 400) {
                if (error.response.data.detail === 'Twoja aplikacja na to stanowisko już została wysłana.') {
                    alert('Już zaaplikowałeś na to stanowisko za pomocą tego CV.');
                } else if (error.response.data.detail === 'Nie posiadasz wygenerowanego Lebenslauf.') {
                    alert('Nie posiadasz wygenerowanego Lebenslauf.');
                } else {
                    alert('Wystąpił błąd podczas aplikowania: ' + error.response.data.detail);
                }
            } else {
                console.error('Error sending application:', error.response.data);
                alert('Wystąpił błąd podczas aplikowania.');
            }
        } else {
            console.error('Error sending application:', error.message);
            alert('Wystąpił błąd podczas aplikowania.');
        }
    });
    
};
const freeOrigin = () => {
  setOryginal(true);
}
const handleVersion = () => {
  if (ad && ad.is_de) {
      return (
          <Button variant="primary" className="mb-3" onClick={freeOrigin}>
              Oryginał
          </Button>
      );
  } else {
      return null;
  }
};


  if (loading) return <div className="d-flex justify-content-center"><Spinner animation="border" /></div>;
  if (error) return <Alert variant="danger">Error loading ad: {error.message}</Alert>;

  return (
 <>

    <Container className="work-ad-detail">
    <p><Link className='d-flex align-items-center' to="/praca"><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-house-door" viewBox="0 0 16 16">
    <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4z"/></svg>
    {isTranslated ? 'Arbeitsmarkt' : 'Praca'}
  </Link></p>
      <Row>
        {ad.img && (
          <Col md={6} className="text-center mb-4">
            <Image src={ad.img} alt={ad.name_pl} className='w-100' />
          </Col>
        )}
      <Col md={ad.img ? 6 : 12}>
        <h1>{oryginal ? ad.name_de : ad.name_pl}</h1>
        <p><strong>Lokalizacja:</strong> {oryginal ? ad.city?.name_de : ad.city?.name}, {oryginal ? ad.land?.name_de : ad.land?.name}</p>
        <p><strong>Kategoria:</strong> {oryginal ? ad.category?.name_de : ad.category?.name}</p>
        <p><strong>Stanowisko:</strong> {oryginal ? ad.job?.name_de : ad.job?.name}</p>
        <p><strong>Czas pracy:</strong> {oryginal ? ad.time?.name_de : ad.time?.name}</p>
        <p><strong>Wynagrodzenie:</strong> {oryginal ? ad.wynagrodzenie_de : ad.wynagrodzenie_pl}</p>
        <p><strong>Zakwaterowanie:</strong> {ad.accomodation ? 'Tak' : 'Nie'}</p>
        <p><strong>Umowa niemiecka:</strong> {ad.umowa_niemiecka ? 'Tak' : 'Nie'}</p>
        <p><strong>Opis:</strong> <span dangerouslySetInnerHTML={{ __html: oryginal ? ad.description_de : ad.description_pl }} /></p>
      </Col>
      </Row>
      <Row className="mt-3">
      <Col>
      <p>*To ogłoszenie zostało napisane po niemiecku, dla pewności przeczytaj oryginalną wersję</p>
                {handleVersion()}
            </Col>
        <Col>
        <Button 
  variant="primary" 
  onClick={handleShow} 
  disabled={!isAuthenticated || (!hasObrotny && !hasSzukajacy)}
>
  APLIKUJ
</Button>        
{(!isAuthenticated || (!hasObrotny && !hasSzukajacy)) && (
  <p className="text-danger mt-2">
    Musisz posiadać subskrybcję co najmniej Szukającego by zaaplikować.
  </p>
)}

        </Col>
      </Row>
      <Modal show={show} onHide={handleClose} centered className='w-100' size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Wybierz w jaki sposób chcesz zaaplikować.</Modal.Title>
        </Modal.Header>
        <Modal.Body className='py-5'>
          <Row>
            <Col md={6} className="text-center mb-4">
              <div className='d-block text-start border-end p-5'>
                <p>Mój plik PDF</p>
                <input type="file" className='form-control' name='pdf-file' onChange={handleFileChange} />
              </div>
            </Col>
            <Col md={6} className="text-center mb-4 p-5">
              <div className='d-block text-end border-start'>
                <p>Wygenerowany PDF</p>
                <input type="checkbox" className='form-check-input' name='my-pdf' checked={myPdf} onChange={handleCheckboxChange} />
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Zamknij
          </Button>
          <Button variant="primary" onClick={handleApply}>
            Zapisz zmiany
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
 </>
  );
}

export default WorkAdvertisementDetail;
