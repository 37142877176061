import { useState } from "react";
import { useAuth } from "../config/AuthContext";
import { useTranslate } from "../config/translateContext";
import { useNavigate } from "react-router-dom";
import './Login.css';

export const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { login, loading } = useAuth(); 
  const { isTranslated } = useTranslate();
  const navigate = useNavigate();

  const submit = async (e) => {
    e.preventDefault();
    try {
      const success = await login(username, password);
      if (success) {
        navigate('/'); // Redirect to the homepage
      } else {
        console.error('Login failed');
      }
    } catch (error) {
      console.error('Error during authentication', error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error('Data:', error.response.data);
        console.error('Status:', error.response.status);
        console.error('Headers:', error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        console.error('Request:', error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error Message:', error.message);
      }
    }
  }

  return (
    <div className="Auth-form-container login-main">
      <div className="background">
        <div className="shape circle"></div>
        <div className="shape square"></div>
        <div className="shape triangle"></div>
      </div>
      <form className="Auth-form" onSubmit={submit}>
        <div className="Auth-form-content">
          <h3 className="Auth-form-title my-5">{isTranslated ? 'Einloggen' : 'Zaloguj się'}</h3>
          <div className="form-group mt-3">
            <label className="my-2">{isTranslated ? 'Benutzername' : 'Nazwa użytkownika'}</label>
            <input
              className="form-control mt-1 px-5"
              placeholder="użytkownik/bennuzername"
              name='username'
              type='text'
              value={username}
              required
              onChange={e => setUsername(e.target.value)}
            />
          </div>
          <div className="form-group mt-3">
            <label>{isTranslated ? 'Kennwort' : 'Hasło'}</label>
            <input
              name='password'
              type="password"
              className="form-control mt-1 px-5"
              placeholder="hasło/kennwort"
              value={password}
              required
              onChange={e => setPassword(e.target.value)}
            />
          </div>
          <div className="d-flex w-100 justify-content-center ">
            <div className="gap-2 mt-3">
              <button type="submit" className="btn btn-primary" disabled={loading}>
                {isTranslated ? 'Einloggen' : 'Zaloguj się'}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Login;
